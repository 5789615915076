import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Role = 'PLAYER' | 'TEAM_STAFF' | 'SYSTEM_ADMIN' | 'DATA_ANALYST' | 'SUPER_ADMIN';
export type StaffRole = 'COACH' | 'MANAGER' | 'OFFICE' | null;

export interface UserInfoState {
    id: number | null; // null 허용
    name: string | null; // null 허용
    email: string | null;
    profileImageUrl: string | null;
    role: Role | null; // null 허용
    staffRole: StaffRole; // 이미 null 허용됨
    status: string | null;
    teamNames: string[] | null;
}

const initialState: UserInfoState = {
    id: localStorage.getItem('id') ? Number(localStorage.getItem('id')) : null,
    name: localStorage.getItem('name') ?? null,
    email: localStorage.getItem('email') ?? null,
    profileImageUrl: localStorage.getItem('profileImageUrl'),
    teamNames: localStorage.getItem('teamNames') ? JSON.parse(localStorage.getItem('teamNames')!) : null,

    role: (localStorage.getItem('role') as Role) ?? null,
    staffRole: (localStorage.getItem('staffRole') as StaffRole) ?? null,
    status: localStorage.getItem('status'),
};

const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        setUserInfoState: (state, action: PayloadAction<UserInfoState>) => {
            state.id = action.payload.id; // id 설정
            state.name = action.payload.name; // name 설정
            state.email = action.payload.email;
            state.profileImageUrl = action.payload.profileImageUrl;
            state.teamNames = action.payload.teamNames;

            state.role = action.payload.role;
            state.staffRole = action.payload.staffRole;
            state.status = action.payload.status;

            if (action.payload.id) localStorage.setItem('id', String(action.payload.id));
            if (action.payload.name) localStorage.setItem('name', action.payload.name);
            if (action.payload.email) localStorage.setItem('email', action.payload.email);
            if (action.payload.profileImageUrl) localStorage.setItem('profileImageUrl', action.payload.profileImageUrl);
            if (action.payload.teamNames) {
                localStorage.setItem('teamNames', JSON.stringify(action.payload.teamNames));
            } // 배열은 stringify
            if (action.payload.role) localStorage.setItem('role', action.payload.role);
            if (action.payload.staffRole) localStorage.setItem('staffRole', action.payload.staffRole);
            if (action.payload.status) localStorage.setItem('status', action.payload.status);
        },
        userInfoRemove: (state) => {
            state.id = null;
            state.name = null;
            state.email = null;
            state.profileImageUrl = null;
            state.teamNames = null;

            state.role = null;
            state.staffRole = null;
            state.status = null;

            localStorage.removeItem('id');
            localStorage.removeItem('name');
            localStorage.removeItem('email');
            localStorage.removeItem('profileImageUrl');
            localStorage.removeItem('teamNames');

            localStorage.removeItem('role');
            localStorage.removeItem('staffRole');
            localStorage.removeItem('status');
        },
    },
});

export const { setUserInfoState, userInfoRemove } = userInfoSlice.actions;
export default userInfoSlice.reducer;
