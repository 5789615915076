// hooks
import { useLocation } from "react-router-dom";
// reudx
import { useSelector } from "react-redux";
import { RootState } from "store"; // Redux Store 타입 정의

// layouts
import PlayerLayout from "components/layouts/playerLayout";
import StaffLayout from "components/layouts/staffLayout";
const Layout = () => {
  const location = useLocation().pathname;
  const { role, staffRole } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
  console.log(location);
  const exceptionRoute = [
    "/",
    "/signIn",
    "/signIn/email",
    "/login/oauth",
    "/login/authentication-failure",
    "/initial",
  ];
  return (
    <>
      {exceptionRoute.includes(location) ? (
        <></>
      ) : role === "PLAYER" ? (
        <PlayerLayout />
      ) : role === "TEAM_STAFF" ? (
        <StaffLayout />
      ) : (
        <></>
      )}
    </>
  );
};

export default Layout;
