export const handleTelValue = (e: React.ChangeEvent<HTMLInputElement>) => {
  let value = e.target.value.replace(/[^0-9]/g, ""); // 숫자만 남김
  if (value.length <= 3) {
    return (value = value); // 3자리까지는 그대로
  } else if (value.length <= 7) {
    return (value = value.slice(0, 3) + "-" + value.slice(3)); // 3-4 형식
  } else {
    return (value =
      value.slice(0, 3) + "-" + value.slice(3, 7) + "-" + value.slice(7, 11)); // 3-4-4 형식
  }
};
