// hooks
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// assets
import aisoccerWhiteFont from "assets/logos/font-logo/aisoccer-white-font.webp";
// icons
import { ReactComponent as SettingIcon } from "assets/svgs/setting.svg";
import { ReactComponent as BarChartIcon } from "assets/svgs/barChart.svg";
import VideoIcon from "assets/svgs/video.png";
import VideoActiveIcon from "assets/svgs/videoActive.png";
// common components
import GreenToggle from "components/toggle/GreenToggle";
interface selectedType {
  title: string;
  value: string;
  link?: string;
}
interface TabItem {
  title: string;
  link: string;
  value: string;
  icon: React.ReactNode;
  subTabOptions?: selectedType[];
}
const Header = () => {
  const tabList: TabItem[] = [
    {
      title: "관리",
      link: "/staff/control/lineUp",
      value: "control",
      icon: <SettingIcon />,
      subTabOptions: [
        { title: "선수단", value: "lineUp", link: "/staff/control/lineUp" },
        { title: "선수평가", value: "rating", link: "/staff/control/rating" },
        { title: "일정", value: "schedule", link: "/staff/control/schedule" },
        { title: "의료센터", value: "medical", link: "/staff/control/medical" },
      ],
    },
    {
      title: "측정",
      link: "/staff/measurement",
      value: "measurement",
      icon: <BarChartIcon />,
    },
  ];

  const location = useLocation().pathname;
  const [activeTab, setActiveTab] = useState("control");
  const [selectedSubTab, setSelectedSubTab] = useState<selectedType>({
    title: "선수단",
    value: "lineUp",
    link: "/staff/control/lineUp",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const path = location.split("/");
    const currentTab = path[2] || "control";
    setActiveTab(currentTab);

    if (path[3]) {
      const activeTabData = tabList.find((tab) => tab.value === currentTab);
      const currentSubTab = activeTabData?.subTabOptions?.find(
        (option) => option.value === path[3]
      );
      if (currentSubTab) {
        setSelectedSubTab(currentSubTab);
      }
    } else if (currentTab === "control") {
      setSelectedSubTab({
        title: "선수단",
        value: "lineUp",
        link: "/staff/control/lineUp",
      });
    } else {
      setSelectedSubTab({
        title: "",
        value: "",
        link: "",
      });
    }
  }, [location]);

  return (
    <header className="py-[30px] flex justify-between items-center">
      <img
        src={aisoccerWhiteFont}
        alt="aisoccerWhiteFont"
        className="w-[173px]"
      />
      <div className="flex w-[970px] items-center text-secondary-mGrayDark text-subtitle-small-bold gap-[40px]">
        {tabList.map((tab) => (
          <div key={tab.value} className="flex gap-[20px]">
            <button
              className={`flex gap-[10px] items-center ${
                tab.value === activeTab
                  ? "text-primary-green"
                  : "text-secondary-mGrayDark"
              }`}
              onClick={() => {
                setActiveTab(tab.value);
                navigate(tab.link);
              }}
            >
              {tab.icon}
              <span>{tab.title}</span>
            </button>
            {tab.subTabOptions && (
              <GreenToggle
                options={tab.subTabOptions}
                selected={selectedSubTab}
                setSelected={(selected) => {
                  setSelectedSubTab(selected);
                  if (selected.link) {
                    navigate(selected.link);
                  }
                }}
              />
            )}
          </div>
        ))}
      </div>
    </header>
  );
};

export default Header;
