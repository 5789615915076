// hooks
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// utils
import { ENDPOINT_MANAGEMENT_PROFILE } from 'utils/consts/apiEndpoint';
import { playerProfileSortMatcher, majorFootMatcher } from 'utils/matchers/userInfoMatcher';
import { debounce } from 'utils/debounce';
// commons
import EmptyBar from 'components/emptyBar';
import CustomTable from 'components/table/CustomTable';
import ControlSubHeader from 'pages/staff/components/ControlSubHeader';
import LineUpDataSummary from 'pages/staff/control/lineUp/LineUpDataSummary';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const LineUp = () => {
    const toDay = new Date();
    const toDayYear = String(toDay.getFullYear());
    const [searchParams] = useSearchParams();
    const paramYear = searchParams.get('year') || toDayYear;
    const paramAgeGroup = searchParams.get('ageGroup') || '';
    const { staffRole } = useSelector((state: RootState) => state.userInfo);

    const [rows, setRows] = useState<any>([]);

    const [sortType, setSortType] = useState('PLAYER_UNIFORM_NUMBER_ASC');

    const getManagementPlayerProfile = useApiQuery(
        ['getManagementPlayerProfile', paramYear, paramAgeGroup],
        ENDPOINT_MANAGEMENT_PROFILE.season.players(`${paramYear}`, {
            ageGroup: staffRole === 'OFFICE' && paramAgeGroup ? paramAgeGroup : undefined,
            year: paramYear || undefined,
            sortType: sortType,
        }),
        'get'
    );
    const getManagementTeamProfile = useApiQuery(
        ['getManagementTeamProfile', paramYear, paramAgeGroup],
        ENDPOINT_MANAGEMENT_PROFILE.season.team(`${paramYear}`, {
            ageGroup: staffRole === 'OFFICE' && paramAgeGroup ? paramAgeGroup : undefined,
            year: paramYear || undefined,
        }),
        'get'
    );

    const handleSort = (key: string, sort: 'ASC' | 'DESC') => {
        const customizedSort = playerProfileSortMatcher(key, sort);
        setSortType(customizedSort);
    };

    useEffect(() => {
        if (getManagementPlayerProfile.isSuccess && getManagementPlayerProfile.data) {
            const { players, teamInfo } = getManagementPlayerProfile.data;
            const customizedRow = handleRows(players, paramYear);
            setRows(customizedRow);
        }
    }, [getManagementPlayerProfile?.isSuccess, getManagementPlayerProfile?.data]);

    useEffect(() => {
        if (sortType && !getManagementPlayerProfile?.isLoading) {
            // 사용 예시
            const debouncedRefetch = debounce(() => {
                getManagementPlayerProfile.refetch(); // refetch를 지연시킴
            }, 300); // 300ms 후 실행

            debouncedRefetch(); // 이 함수가 실행되는 부분
        }
    }, [sortType]);

    return (
        <div className="w-[1400px] m-auto">
            {/* 배경과 심볼 */}

            <ControlSubHeader seasonStats={getManagementTeamProfile?.data?.seasonStats} />
            <EmptyBar customStyle={'h-[35px]'} />

            <LineUpDataSummary
                physicalStats={getManagementTeamProfile?.data?.physicalStats}
                topPlayers={getManagementTeamProfile?.data?.topPlayers}
            />
            <EmptyBar customStyle={'h-[20px]'} />
            <div className="w-full p-[30px] bg-primary-white rounded-[10px]">
                <div className="text-subtitle-medium-bold mb-[20px]">선수명단</div>

                <CustomTable
                    columns={columns}
                    rows={rows}
                    minWidth={'min-w-[116px]'}
                    equalWidth={false}
                    sortInfoFunc={handleSort}
                />
            </div>
            <EmptyBar customStyle={'h-[20px]'} />
        </div>
    );
};

export default LineUp;

export const columns = [
    { key: 'name', label: '이름', sortable: true },
    { key: 'uniformNumber', label: '배번', sortable: true },
    { key: 'age', label: '나이', sortable: true },
    { key: 'position', label: '포지션' },
    { key: 'majorFoot', label: '주발', sortable: false },
    { key: 'lastFiveRatings', label: '지난 5경기 평점', sortable: true },
    { key: 'seasonAverageRating', label: '평균 평점', sortable: true },
    { key: 'totalAppearances', label: '총 출장', sortable: true },
    { key: 'totalGoals', label: '골 수', sortable: true },
    { key: 'totalAssists', label: '도움 수', sortable: true },
    {
        key: 'link',
        label: '상세보기',
        sortable: false,
        link: '/staff/control/rating',
    },
];
export const handleRows = (rows: any[], paramYear: string) => {
    return rows.map((row) => ({
        uniformNumber: row.uniformNumber,
        name: row.name,
        age: row.age,
        position: row.positions.join(', '), // 포지션이 배열이므로 쉼표로 구분
        majorFoot: majorFootMatcher(row.majorFoot),
        seasonAverageRating: row.seasonAverageRating,
        lastFiveRatings: row.lastFiveRatings.join(','),
        totalAppearances: row.totalAppearances,
        totalGoals: row.totalGoals,
        totalAssists: row.totalAssists,
        link: `/staff/control/lineUp/${paramYear}?player=${row.playerId}`, // 상세보기 링크
    }));
};
