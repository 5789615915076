// assets
import profile from "assets/logos/symbol/profile.webp";
interface PlayerType {
  playerId: number;
  profileImageUrl: string;
  uniformNumber: number;
  name: string;
  intValue: number;
  doubleValue: number;
}
interface LineUpDataSummaryType {
  physicalStats: {
    averageHeight: number;
    averageWeight: number;
  };
  topPlayers: {
    topScorer: PlayerType | null;
    topAssister: PlayerType | null;
    mostAppearances: PlayerType | null;
    highestRating: PlayerType | null;
  };
}

const LineUpDataSummary = ({
  physicalStats = { averageHeight: 0, averageWeight: 0 },
  topPlayers = {
    topScorer: null,
    topAssister: null,
    mostAppearances: null,
    highestRating: null,
  },
}: LineUpDataSummaryType) => {
  const { averageHeight = null, averageWeight = null } = physicalStats;
  const topPlayerLabels = [
    { label: "득점 1위", key: "topScorer" },
    { label: "도움 1위", key: "topAssister" },
    { label: "출전 1위", key: "mostAppearances" },
    { label: "평점 1위", key: "highestRating" },
  ] as const;

  return (
    <div className="w-full bg-white h-[360px] rounded-[10px] p-[30px] flex gap-[80px]">
      <div className="w-[230px]">
        <div className="flex gap-[14px]">
          <div className="text-subtitle-medium-bold">데이터 요악</div>
        </div>

        <div className="m-auto h-[240px] w-[251px] flex flex-col justify-center gap-[20px]">
          <div className="flex items-center">
            <div className="border-[5px] border-l border-primary-purple h-[48px] rounded-xl mr-[18px]"></div>
            <div
              className="text-[20px] text-[#868686] font-semibold w-[88px]"
              style={{ wordBreak: "keep-all", overflowWrap: "break-word" }} //한국어 띄어쓰기 단위로 줄바꿈
            >
              팀 평균 신장
            </div>
            <div className="text-[50px] font-bold flex gap-[5px] items-center">
              <span>{averageHeight?.toFixed(1)}</span>
              <span className="text-[22px] font-medium text-[#7c7c7c]">kg</span>
            </div>
          </div>
          <div className="flex items-center">
            <div className="border-[5px] border-l border-primary-purple h-[48px] rounded-xl mr-[18px]"></div>
            <div
              className="text-[20px] text-[#868686] font-semibold w-[88px]"
              style={{ wordBreak: "keep-all", overflowWrap: "break-word" }} //한국어 띄어쓰기 단위로 줄바꿈
            >
              팀 평균 체중
            </div>
            <div className="text-[50px] font-bold flex gap-[5px] items-center">
              <span>{averageWeight?.toFixed(1)}</span>
              <span className="text-[22px] text-[#7c7c7c] font-medium">kg</span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[1030px] h-[300px] flex gap-[20px]">
        {topPlayerLabels.map((el) => {
          const playerData = topPlayers[el?.key];
          return (
            <button
              key={el?.key}
              className="p-[34px] flex flex-col gap-[20px] justify-center items-center w-[242.5px] h-[300px]
                        bg-[#f8f8f8f8] rounded-[14px] hover:bg-white hover:border-[2px] hover:border-primary-purple
                        "
            >
              <img
                src={
                  playerData?.profileImageUrl
                    ? playerData?.profileImageUrl
                    : profile
                }
                alt="profile"
                className="w-[100px] h-[100px]"
              />
              <div className="flex flex-col gap-[8px]">
                <div className="text-title-small-bold">{`${
                  playerData?.uniformNumber
                    ? `${playerData?.uniformNumber} . `
                    : ""
                } ${playerData?.name ? playerData?.name : "미정"}`}</div>
                <div className="text-[14px] font-bold w-[70px] h-[29px] bg-primary-purple rounded-[80px] justify-center items-end flex text-center text-primary-white m-auto py-[4px]">
                  {el.label}
                </div>
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default LineUpDataSummary;
