// hooks
import React, { useState, useRef, useEffect } from 'react';
// icons
import { ReactComponent as CheckIcon } from 'assets/svgs/check.svg';
import { ReactComponent as ArrowIcon } from 'assets/svgs/arrow.svg';

interface DropdownOption {
    value: string;
    label: string;
}

interface DropdownProps {
    options: DropdownOption[];
    value?: string;
    defaultValue?: string;
    paramKey?: string;
    onChange: (value: string) => void;
    activeFunc?: (value: string) => void;
    parmaKey?: string;
    placeholder?: string;
    disabled?: boolean;
    error?: boolean;
    className?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
    options,
    value,
    defaultValue,
    onChange,
    activeFunc,
    placeholder = '선택해주세요',
    disabled = false,
    error = false,
    className = '',
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    // 외부 클릭 시 드롭다운 닫기
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const selectedOption = options.find((option) => option.value === value);

    const handleToggle = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };

    const handleOptionSelect = (optionValue: string) => {
        if (!disabled) {
            onChange(optionValue);
            if (activeFunc) activeFunc(optionValue);
            setIsOpen(false);
        }
    };

    // 드롭다운 상태에 따른 스타일
    const getDropdownStyles = () => {
        if (disabled) {
            return 'bg-secondary-mGrayLight border border-secondary-mGrayDark text-secondary-mGrayDark text-context-bold';
        }
        if (error) {
            return 'bg-primary-white border border-status-error text-status-error text-context-bold';
        }
        if (isOpen) {
            return 'bg-primary-purple text-primary-white text-context-bold';
        }
        return 'bg-primary-black text-primary-white text-context-bold';
    };

    return (
        <div
            ref={dropdownRef}
            className={`relative  ${className ? className : 'w-full'}`}
        >
            {/* 드롭다운 헤더 */}
            <div
                onClick={handleToggle}
                className={`
          flex justify-between items-center gap-[12px]
          px-[12px] py-[12px] rounded-[8px]
          cursor-pointer
          transition-all duration-200
          ${getDropdownStyles()}
        `}
            >
                <span>{selectedOption ? selectedOption.label : defaultValue ? defaultValue : placeholder}</span>
                <ArrowIcon
                    className={`
            transition-transform duration-200
            ${isOpen ? 'rotate-180' : 'rotate-0'}
          `}
                />
            </div>

            {/* 드롭다운 리스트 */}
            {isOpen && (
                <div
                    className="
            absolute z-10 w-full
            border border-primary-purple
            rounded-[10px]
            max-h-60 overflow-y-auto mt-[5px]
          "
                >
                    {options.map((option) => (
                        <div
                            key={option.value}
                            onClick={() => handleOptionSelect(option.value)}
                            className={`
                px-[12px] py-[12px]  text-context-regular
                flex justify-between items-center gap-[12px]
                cursor-pointer
                hover:bg-[#EDECFF] text-primary-purple
                transition-colors duration-200
                ${value === option.value ? 'bg-[#EDECFF]' : 'bg-primary-white'}
              `}
                        >
                            {option.label}
                            {value === option.value && <CheckIcon />}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Dropdown;

// 사용 방법
// const [selectedValueDD, setSelectedValueDD] = useState('');

// const options = [
//     { value: 'option1', label: 'selcted List1' },
//     { value: 'option2', label: '옵션 2' },
//     { value: 'option3', label: '옵션 3' },
// ];

// <Dropdown
// options={options}
// value={selectedValueDD}
// onChange={setSelectedValueDD}
// />

// {/* 비활성화된 드롭다운 */}
// <Dropdown
// options={options}
// value={selectedValueDD}
// onChange={setSelectedValueDD}
// disabled
// />

// {/* 에러 상태의 드롭다운 */}
// <Dropdown
// options={options}
// value={selectedValueDD}
// onChange={setSelectedValueDD}
// error
// />
