import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// assets
import aisoccerWhiteFont from 'assets/logos/font-logo/aisoccer-white-font.webp';
import aisoccerWhiteSymbol from 'assets/logos/symbol/aisoccer-white-symbol.webp';
// icons
import { ReactComponent as NaverIcon } from 'assets/svgs/naver.svg';
import { ReactComponent as KakaoIcon } from 'assets/svgs/kakao.svg';
import { ReactComponent as EmailIcon } from 'assets/svgs/email.svg';

export type Role = 'player' | 'coach' | 'staff' | 'admin';
export type SubRole = 'coach' | 'supervision' | 'office' | 'system' | 'analysis' | 'super';

const SignIn: React.FC = () => {
    const navigate = useNavigate();

    const kakaoLink = `https://dev.aisoccer.ai/oauth2/authorization/kakao`;
    const naverLink = `https://dev.aisoccer.ai/oauth2/authorization/naver`;

    const handleLogin = (link: string) => {
        return window.location.assign(link);
    };
    return (
        <div
            id="authPage"
            className="relative h-screen "
        >
            {/* 배경과 심볼 */}
            <div className="absolute top-0 left-0 z-[-1] w-full h-full">
                {/* 배경색 나누기 */}
                <div className="h-[400px] bg-primary-black"></div>
                {/* 심볼 이미지 */}
                <img
                    src={aisoccerWhiteSymbol}
                    alt="aisoccerWhiteSymbol"
                    className="absolute z-0 top-0 right-0 w-[470px]"
                />
            </div>

            <div className="h-screen ">
                {/* 로고를 form 상단 바로 위에 배치 */}
                <div
                    className={`flex gap-[20px] flex-col justify-center items-center  pc:pt-[50px] monitor:pt-[100px] pt-[140px] select-none`}
                >
                    <img
                        src={aisoccerWhiteFont}
                        alt="aisoccerWhiteFont"
                        className="w-[300px]"
                    />
                    <div className="text-[28px] font-semibold text-primary-white">
                        Goal Management <span className="text-primary-green">Data Solution</span>
                    </div>
                </div>
                <div className="flex items-center justify-center mt-[83px]">
                    <div className="relative px-[60px] py-[50px] bg-white rounded-[10px] flex flex-col justify-center">
                        <h1 className="font-bold text-center text-title-medium-bold text-secondary-lGrayMid">LOGIN</h1>
                        <div className="flex flex-col gap-[14px] py-[24px]">
                            <button
                                type="button"
                                onClick={() => handleLogin(kakaoLink)}
                                className="bg-[#FEE500] w-[400px] rounded-[8px] p-[18px] text-context-bold text-[#381E1F] h-[60px] flex items-center justify-center gap-[12px]"
                            >
                                <KakaoIcon />
                                카카오 계정으로 시작하기
                            </button>
                            <button
                                type="button"
                                onClick={() => handleLogin(naverLink)}
                                className="bg-[#03C75A] w-[400px] text-primary-white p-[18px] h-[60px] rounded-[8px] text-context-bold flex items-center justify-center gap-[12px]"
                            >
                                <NaverIcon />
                                네이버 계정으로 시작하기
                            </button>

                            <button
                                onClick={() => navigate('/signIn/email')}
                                className="bg-[#F8F8F8] w-[400px] p-[18px] text-secondary-dGrayMid rounded-[8px] text-context-bold h-[60px] flex items-center justify-center gap-[12px]"
                            >
                                <span className=" w-[40px] h-[40px] bg-[#111111] flex justify-center items-center rounded-[6px]">
                                    <EmailIcon className="" />
                                </span>
                                이메일 계정으로 시작하기
                            </button>
                        </div>
                        <div className="flex justify-between text-label-regular text-secondary-lGrayDark">
                            <div>회원 가입은 정책 동의를 포함합니다.</div>
                            <div className="flex gap-[8px]">
                                <button className="border-b border-secondary-lGrayDark">이용약관</button>
                                <button className="border-b border-secondary-lGrayDark">개인정보처리방침</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h-[20px] bg-secondary-mGrayMid"></div>
            </div>
        </div>
    );
};

export default SignIn;
