import { ApexOptions } from 'apexcharts';

export const options: ApexOptions = {
    chart: {
        height: 350,
        type: 'line',
        toolbar: {
            show: false, // 도구바 표시
        },
    },

    forecastDataPoints: {
        count: 7,
    },
    stroke: {
        width: 5,
        curve: 'smooth',
    },
    xaxis: {
        type: 'datetime',
        categories: [
            '1/11/2000',
            '2/11/2000',
            '3/11/2000',
            '4/11/2000',
            '5/11/2000',
            '6/11/2000',
            '7/11/2000',
            '8/11/2000',
            '9/11/2000',
            '10/11/2000',
            '11/11/2000',
            '12/11/2000',
            '1/11/2001',
            '2/11/2001',
            '3/11/2001',
            '4/11/2001',
            '5/11/2001',
            '6/11/2001',
        ],
        // tickAmount: 10,
        // labels: {
        // formatter: function(value, timestamp, opts) {
        //   return opts.dateFormatter(new Date(timestamp), 'dd MMM')
        // }
        // },
    },
    fill: {
        type: 'gradient',
        gradient: {
            shade: 'dark',
            gradientToColors: ['#FDD835'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
        },
    },
    title: {
        // 제목
        text: 'Forecast',
        align: 'left',
        style: {
            fontSize: '16px',
            color: '#666',
        },
    },
};
