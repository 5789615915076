import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// icons
import { ReactComponent as MenuIcon } from 'assets/svgs/menu.svg';
// common
import StatusLabel from 'components/label/Default';
import Dropdown from 'components/dropdown/Default';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';
const ScheduleSubHeader = () => {
    const handleParamNavigate = useParamNavigate();
    const options = [
        { value: 'U12', label: 'U12' },
        { value: 'U15', label: 'U15' },
        { value: 'U18', label: 'U18' },
    ];
    const years = [
        { value: '2023', label: '2023' },
        { value: '2024', label: '2024' },
        { value: '2025', label: '2025' },
    ];
    const [youthRange, setYouthRange] = useState('U12');
    const [year, setYear] = useState('2023');

    useEffect(() => {
        if (youthRange)
            handleParamNavigate({
                ageGroup: youthRange,
            });
    }, [youthRange]);

    return (
        <div className="h-[80px]">
            <div className="flex items-center justify-between">
                <div className="flex gap-[14px] items-center">
                    <MenuIcon className="text-[#BABABD] w-[30px] h-[30px]  mr-[6px]" />
                    <div className="text-primary-white text-title-small-bold">
                        <div>갤로핑 FC 선수단</div>
                    </div>
                    <Dropdown
                        options={options}
                        value={youthRange}
                        defaultValue={'U12'}
                        onChange={setYouthRange}
                        className={'w-[82px] border rounded-[8px] border-primary-white'}
                    />
                    <Dropdown
                        options={years}
                        value={year}
                        defaultValue={'2024'}
                        onChange={setYear}
                        className={'w-[93px] border rounded-[8px] border-primary-white'}
                    />
                </div>
                <div className="flex w-fit gap-[80px] ">
                    <div className="min-w-[311px] flex gap-[17px]  h-[48px] text-[#868686]">
                        <div className="w-[5px] bg-primary-green rounded-[5px]"></div>
                        <div className="flex gap-[15px] items-center">
                            <div
                                className="w-[88px]  text-[20px] font-semibold leading-[24px]"
                                style={{ wordBreak: 'keep-all', overflowWrap: 'break-word' }} //한국어 띄어쓰기 단위로 줄바꿈
                            >
                                {'2024시즌 승률'}
                            </div>
                            <div className="flex items-center gap-[12px] w-[176.5px]">
                                <div className="flex items-center gap-[5px]">
                                    <span className="text-[50px] text-primary-white font-bold">{60}</span>
                                    <span className="text-[22px]">{'%'}</span>
                                </div>
                                <div>
                                    <StatusLabel
                                        status={'up'}
                                        customColor="bg-primary-green text-primary-black font-bold  text-[16px]"
                                    >
                                        {'10%'}
                                    </StatusLabel>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="min-w-[349px] flex gap-[18px]  h-[48px] text-[#868686]">
                        <div className="w-[5px] bg-primary-green rounded-[5px]"></div>
                        <div className="flex gap-[15px] items-center">
                            <div
                                className="w-[72px] font-bold leading-[24px] text-[20px]"
                                style={{ wordBreak: 'keep-all', overflowWrap: 'break-word' }} //한국어 띄어쓰기 단위로 줄바꿈
                            >
                                {'총 시즌 정보'}
                            </div>
                            <div className="flex items-center gap-[25px] ">
                                <div className="flex items-center gap-[5px]">
                                    <span className="text-[50px] text-primary-green font-bold">{60}</span>
                                    <span className="text-[22px]">{'승'}</span>
                                </div>
                                <div className="flex items-center gap-[5px]">
                                    <span className="text-[50px] text-primary-white font-bold">{5}</span>
                                    <span className="text-[22px]">{'무'}</span>
                                </div>
                                <div className="flex items-center gap-[5px]">
                                    <span className="text-[50px] text-status-error font-bold">{8}</span>
                                    <span className="text-[22px]">{'패'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScheduleSubHeader;
