import { useNavigate, useLocation } from 'react-router-dom';

export const useParamNavigate = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const updateQueryParams = (
        paramsToUpdate: Record<string, string | undefined>,
        replaceHistory: boolean = true, // 기본값으로 히스토리 덮어쓰기 설정
        newPathname?: string // 선택적으로 새로운 경로를 받을 수 있도록 추가
    ) => {
        const params = new URLSearchParams(location.search);

        // 기존 쿼리 파라미터와 새로운 쿼리 병합
        Object.entries(paramsToUpdate).forEach(([key, value]) => {
            if (value) {
                params.set(key, value); // 업데이트
            } else {
                params.delete(key); // 빈 값인 경우 삭제
            }
        });

        // URL 업데이트
        navigate(
            {
                pathname: `${newPathname || location.pathname}`, // 기존 경로 유지
                search: params.toString(), // 업데이트된 쿼리 문자열
            },
            { replace: replaceHistory } // 히스토리 덮어쓰기 여부 설정
        );
    };

    return updateQueryParams;
};
// updateQueryParams({ page: '1', sort: 'desc' }, false, '/new-path');
