export const lineUpData = [
    {
        backNumber: 1,
        name: '손흥민',
        age: '서울',
        position: 'GK',
        mainFoot: 'R',
        lastFiveMatchesAverage: '7.00',
        average: 6.84,
        participation: 20,
        goal: 0,
        assist: 0,
        link: '/staff/control/lineUp/1',
    },
    {
        backNumber: 2,
        name: '손흥민',
        age: '서울',
        position: 'GK',
        mainFoot: 'R',
        lastFiveMatchesAverage: '7.00',
        average: 6.84,
        participation: 20,
        goal: 0,
        assist: 0,
        link: '/staff/control/lineUp/1',
    },
    {
        backNumber: 3,
        name: '손흥민',
        age: '서울',
        position: 'GK',
        mainFoot: 'R',
        lastFiveMatchesAverage: '7.00',
        average: 6.84,
        participation: 20,
        goal: 0,
        assist: 0,
        link: '/staff/control/lineUp/1',
    },
    {
        backNumber: 4,
        name: '손흥민',
        age: '서울',
        position: 'GK',
        mainFoot: 'R',
        lastFiveMatchesAverage: '7.00',
        average: 6.84,
        participation: 20,
        goal: 0,
        assist: 0,
        link: '/staff/control/lineUp/1',
    },

    {
        backNumber: 5,
        name: '손흥민',
        age: '서울',
        position: 'GK',
        mainFoot: 'R',
        lastFiveMatchesAverage: '7.00',
        average: 6.84,
        participation: 20,
        goal: 0,
        assist: 0,
        link: '/staff/control/lineUp/1',
    },
    {
        backNumber: 6,
        name: '손흥민',
        age: '서울',
        position: 'GK',
        mainFoot: 'R',
        lastFiveMatchesAverage: '7.00',
        average: 6.84,
        participation: 20,
        goal: 0,
        assist: 0,
        link: '/staff/control/lineUp/1',
    },
    {
        backNumber: 7,
        name: '손흥민',
        age: '서울',
        position: 'GK',
        mainFoot: 'R',
        lastFiveMatchesAverage: '7.00',
        average: 6.84,
        participation: 20,
        goal: 0,
        assist: 0,
        link: '/staff/control/lineUp/1',
    },
    {
        backNumber: 3,
        name: '손흥민',
        age: '서울',
        position: 'GK',
        mainFoot: 'R',
        lastFiveMatchesAverage: '7.00',
        average: 6.84,
        participation: 20,
        goal: 0,
        assist: 0,
        link: '/staff/control/lineUp/1',
    },
    {
        backNumber: 8,
        name: '손흥민',
        age: '서울',
        position: 'GK',
        mainFoot: 'R',
        lastFiveMatchesAverage: '7.00',
        average: 6.84,
        participation: 20,
        goal: 0,
        assist: 0,
        link: '/staff/control/lineUp/1',
    },
    {
        backNumber: 9,
        name: '손흥민',
        age: '서울',
        position: 'GK',
        mainFoot: 'R',
        lastFiveMatchesAverage: '7.00',
        average: 6.84,
        participation: 20,
        goal: 0,
        assist: 0,
        link: '/staff/control/lineUp/1',
    },
    {
        backNumber: 10,
        name: '손흥민',
        age: '서울',
        position: 'GK',
        mainFoot: 'R',
        lastFiveMatchesAverage: '7.00',
        average: 6.84,
        participation: 20,
        goal: 0,
        assist: 0,
        link: '/staff/control/lineUp/1',
    },
    {
        backNumber: 3,
        name: '손흥민',
        age: '서울',
        position: 'GK',
        mainFoot: 'R',
        lastFiveMatchesAverage: '7.00',
        average: 6.84,
        participation: 20,
        goal: 0,
        assist: 0,
        link: '/staff/control/lineUp/1',
    },
    {
        backNumber: 11,
        name: '손흥민',
        age: '서울',
        position: 'GK',
        mainFoot: 'R',
        lastFiveMatchesAverage: '7.00',
        average: 6.84,
        participation: 20,
        goal: 0,
        assist: 0,
        link: '/staff/control/lineUp/1',
    },
    {
        backNumber: 12,
        name: '손흥민',
        age: '서울',
        position: 'GK',
        mainFoot: 'R',
        lastFiveMatchesAverage: '7.00',
        average: 6.84,
        participation: 20,
        goal: 0,
        assist: 0,
        link: '/staff/control/lineUp/1',
    },
    {
        backNumber: 13,
        name: '손흥민',
        age: '서울',
        position: 'GK',
        mainFoot: 'R',
        lastFiveMatchesAverage: '7.00',
        average: 6.84,
        participation: 20,
        goal: 0,
        assist: 0,
        link: '/staff/control/lineUp/1',
    },
    {
        backNumber: 14,
        name: '손흥민',
        age: '서울',
        position: 'GK',
        mainFoot: 'R',
        lastFiveMatchesAverage: '7.00',
        average: 6.84,
        participation: 20,
        goal: 0,
        assist: 0,
        link: '상세보기',
    },
    {
        backNumber: 15,
        name: '손흥민',
        age: '서울',
        position: 'GK',
        mainFoot: 'R',
        lastFiveMatchesAverage: '8.00',
        average: 6.84,
        participation: 20,
        goal: 0,
        assist: 0,
        link: '상세보기',
    },
];

export const ratingData = [
    {
        grade: '중3',
        backNumber: 19,
        position: 'GK',
        name: '손흥민',
        rank: 5,
        leagueParticipant: 3,
        history: '갤로핑FC',
        link: '/staff/control/lineUp/1',
        rating: '/staff/control/rating/1',
    },
    {
        grade: '중3',
        backNumber: 19,
        position: 'GK',
        name: '손흥민',
        rank: 5,
        leagueParticipant: 3,
        history: '갤로핑FC',
        link: '/staff/control/lineUp/1',
        rating: '/staff/control/rating/1',
    },
    {
        grade: '중3',
        backNumber: 19,
        position: 'GK',
        name: '손흥민',
        rank: 5,
        leagueParticipant: 3,
        history: '갤로핑FC',
        link: '/staff/control/lineUp/1',
        rating: '/staff/control/rating/1',
    },

    {
        grade: '중3',
        backNumber: 19,
        position: 'GK',
        name: '손흥민',
        rank: 5,
        leagueParticipant: 3,
        history: '갤로핑FC',
        link: '/staff/control/lineUp/1',
        rating: '/staff/control/rating/1',
    },
    {
        grade: '중3',
        backNumber: 19,
        position: 'GK',
        name: '손흥민',
        rank: 5,
        leagueParticipant: 3,
        history: '갤로핑FC',
        link: '/staff/control/lineUp/1',
        rating: '/staff/control/rating/1',
    },
    {
        grade: '중3',
        backNumber: 19,
        position: 'GK',
        name: '손흥민',
        rank: 5,
        leagueParticipant: 3,
        history: '갤로핑FC',
        link: '/staff/control/lineUp/1',
        rating: '/staff/control/rating/1',
    },
    {
        grade: '중3',
        backNumber: 19,
        position: 'GK',
        name: '손흥민',
        rank: 5,
        leagueParticipant: 3,
        history: '갤로핑FC',
        link: '/staff/control/lineUp/1',
        rating: '/staff/control/rating/1',
    },
    {
        grade: '중3',
        backNumber: 19,
        position: 'GK',
        name: '손흥민',
        rank: 5,
        leagueParticipant: 3,
        history: '갤로핑FC',
        link: '/staff/control/lineUp/1',
        rating: '/staff/control/rating/1',
    },
    {
        grade: '중3',
        backNumber: 19,
        position: 'GK',
        name: '손흥민',
        rank: 5,
        leagueParticipant: 3,
        history: '갤로핑FC',
        link: '/staff/control/lineUp/1',
        rating: '/staff/control/rating/1',
    },
    {
        grade: '중3',
        backNumber: 19,
        position: 'GK',
        name: '손흥민',
        rank: 5,
        leagueParticipant: 3,
        history: '갤로핑FC',
        link: '/staff/control/lineUp/1',
        rating: '/staff/control/rating/1',
    },
    {
        grade: '중3',
        backNumber: 19,
        position: 'GK',
        name: '손흥민',
        rank: 5,
        leagueParticipant: 3,
        history: '갤로핑FC',
        link: '/staff/control/lineUp/1',
        rating: '/staff/control/rating/1',
    },
    {
        grade: '중3',
        backNumber: 19,
        position: 'GK',
        name: '손흥민',
        rank: 5,
        leagueParticipant: 3,
        history: '갤로핑FC',
        link: '/staff/control/lineUp/1',
        rating: '/staff/control/rating/1',
    },
    {
        grade: '중3',
        backNumber: 19,
        position: 'GK',
        name: '손흥민',
        rank: 5,
        leagueParticipant: 3,
        history: '갤로핑FC',
        link: '/staff/control/lineUp/1',
        rating: '/staff/control/rating/1',
    },
];

export const seasonData = [
    {
        matchTitle: '프리미어리그',
        match: 19,
        goal: 10,
        assist: 5,
        average: 6.05,
    },
    {
        matchTitle: '프리미어리그',
        match: 19,
        goal: 10,
        assist: 5,
        average: 6.05,
    },
    {
        matchTitle: '프리미어리그',
        match: 19,
        goal: 10,
        assist: 5,
        average: 6.05,
    },
    {
        matchTitle: '프리미어리그',
        match: 19,
        goal: 10,
        assist: 5,
        average: 6.05,
    },
    {
        matchTitle: '프리미어리그',
        match: 19,
        goal: 10,
        assist: 5,
        average: 6.05,
    },
];
