// commons
import CustomTable from 'components/table/CustomTable';
import LineUpIdSubNavHeader from 'pages/staff/control/lineUp/[id]/LineUpIdSubNavHeader';
import EmptyBar from 'components/emptyBar';
// utils
import { seasonData } from 'utils/tmp/staffTmpData';
// assets
import graySymbol from 'assets/logos/symbol/graySymbol.png';
// icons
import { ReactComponent as LFootIcon } from 'assets/svgs/lFoot.svg';
import { ReactComponent as RFootIcon } from 'assets/svgs/rFoot.svg';

const Season = () => {
    const columns = [
        { key: 'summary', label: '', sortable: false },
        { key: 'position', label: '포지션', sortable: false },
        { key: 'participation', label: '출전 시간', sortable: false },
        { key: 'goal', label: '골', sortable: false },
        { key: 'assist', label: '도움', sortable: false },
        { key: 'average', label: '평점', sortable: false },
        { key: 'comment', label: '코멘트', sortable: false },
    ];
    const rows = [
        {
            summary: '전북(1:0 승, 2024.11.08)',
            position: 'CF',
            participation: '47분',
            goal: 1,
            assist: 2,
            average: 5.6,
            comment: '실점하였으나 빌드업 & 선방에서 안정적으로 잘해주었다.',
        },
        {
            summary: '전북(1:0 승, 2024.11.08)',
            position: 'CF',
            participation: '47분',
            goal: 1,
            assist: 2,
            average: 5.6,
            comment: '실점하였으나 빌드업 & 선방에서 안정적으로 잘해주었다.',
        },
        {
            summary: '전북(1:0 승, 2024.11.08)',
            position: 'CF',
            participation: '47분',
            goal: 1,
            assist: 2,
            average: 5.6,
            comment: '실점하였으나 빌드업 & 선방에서 안정적으로 잘해주었다.',
        },
    ];
    return (
        <div className="w-[1400px] m-auto">
            <LineUpIdSubNavHeader />

            {/* 경력정보 */}

            <div className="flex gap-[20px]">
                <div className="w-[335px] h-[650px] bg-primary-white rounded-[10px] py-[30px] px-[28px]">
                    <div className="flex justify-center items-center rounded-[8px] w-[230px] h-[280px] bg-secondary-mGrayLight m-auto">
                        <img
                            src={graySymbol}
                            alt="graySymbol"
                            className="w-[80px]"
                        />
                    </div>
                    <div className="flex gap-[10px] items-center mt-[30px]">
                        <div className="text-title-small-bold">제리예이츠</div>
                        <span className="w-[79px] h-[29px] bg-[#5E6EFF] rounded-[80px] text-[14px] font-bold flex justify-center items-center text-primary-white">
                            등번호 19
                        </span>
                    </div>
                    <div className="h-[240px] flex flex-col items-start justify-center gap-[20px] m-auto ">
                        <div className="h-[38px] flex items-center">
                            <div className="h-[40px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                            <div className="text-[20px] font-semibold mr-[12px] text-[#868686]">포지션</div>
                            <div className="text-title-small-bold mr-[10px] font-bold">스트라이커(중앙) </div>
                        </div>
                        <div className="h-[38px] flex items-center">
                            <div className="h-[40px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                            <div className="text-[20px] font-semibold mr-[12px] text-[#868686]">신장</div>
                            <div className="text-title-small-bold mr-[10px] font-bold">175</div>
                            <div className="text-[22px] text-[#7c7c7c]">Cm</div>
                        </div>
                        <div className="h-[38px] flex items-center">
                            <div className="h-[40px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                            <div className="text-[20px] font-semibold mr-[12px] text-[#868686]">체중</div>
                            <div className="text-title-small-bold mr-[10px] font-bold">70</div>
                            <div className="text-[22px] text-[#7c7c7c]">Kg</div>
                        </div>
                        <div className="h-[38px] flex items-center">
                            <div className="h-[40px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                            <div className="text-[20px] font-semibold mr-[12px] text-[#868686]">주발</div>
                            <div className="flex items-center text-subtitle-large-regular text-secondary-lGrayMid">
                                <LFootIcon />
                                <div>L</div>
                            </div>
                            <div className="w-[10px]"></div>
                            <div className="flex items-center text-subtitle-large-bold text-primary-purple">
                                <div>R</div>

                                <RFootIcon />
                            </div>
                        </div>
                    </div>
                </div>
                {/* overflow-y-scroll */}
                <div className="h-[878px] w-[1045px] flex flex-col gap-[20px]">
                    <div className="w-[1045px] h-[282px] p-[30px] bg-primary-white rounded-[10px] flex flex-col gap-[20px]">
                        <div className="text-subtitle-medium-bold h-[40px] gap-[10px] flex items-center">
                            <div>1부 리그</div>
                            <span className="w-[49px] h-[29px] rounded-[80px] text-[14px] font-bold bg-primary-green flex justify-center items-center">
                                리그
                            </span>
                        </div>
                        <CustomTable
                            columns={columns}
                            rows={rows.slice(0, 3)}
                            equalWidth={false}
                            rowHeight={'h-[36px]'}
                        />
                    </div>
                    <div className="w-[1045px] h-[282px] p-[30px] bg-primary-white rounded-[10px] flex flex-col gap-[20px]">
                        <div className="text-subtitle-medium-bold h-[40px] gap-[10px] flex items-center">
                            <div>MBC 대회</div>
                            <span className="w-[49px] h-[29px] rounded-[80px] text-[14px] font-bold bg-status-correct flex justify-center items-center">
                                대회
                            </span>
                        </div>
                        <CustomTable
                            columns={columns}
                            rows={rows.slice(0, 3)}
                            equalWidth={false}
                            rowHeight={'h-[36px]'}
                        />
                    </div>
                    <div className="w-[1045px] h-[282px] p-[30px] bg-primary-white rounded-[10px] flex flex-col gap-[20px]">
                        <div className="text-subtitle-medium-bold h-[40px] gap-[10px] flex items-center">
                            <div>KBS 친선</div>
                            <span className="w-[49px] h-[29px] rounded-[80px] text-[14px] font-bold bg-status-yellow flex justify-center items-center">
                                친선
                            </span>
                        </div>
                        <CustomTable
                            columns={columns}
                            rows={rows.slice(0, 3)}
                            equalWidth={false}
                            rowHeight={'h-[36px]'}
                        />
                    </div>
                </div>
            </div>

            <EmptyBar customStyle={'h-[20px]'} />
        </div>
    );
};

export default Season;
