export function injuryStateBgMatcher(status: 'TRAINING' | 'REHABILITATION' | 'INJURED' | 'HOSPITAL' | 'HOME') {
    switch (status) {
        case 'TRAINING':
            return 'bg-primary-green';
        case 'REHABILITATION':
            return 'bg-primary-purple';
        case 'INJURED':
            return 'bg-status-error';
        case 'HOSPITAL':
            return 'bg-primary-purple';
        case 'HOME':
            return 'bg-primary-purple';
        default:
            return 'bg-primary-black text-primary-white';
    }
}

export function injuryMatcher(status: string) {
    switch (status) {
        case 'TRAINING':
            return '훈련';
        case 'REHABILITATION':
            return '재활';
        case 'INJURED':
            return '부상';
        case 'HOSPITAL':
            return '병원';
        case 'HOME':
            return '귀가';
        case '훈련':
            return 'TRAINING';
        case '재활':
            return 'REHABILITATION';
        case '부상':
            return 'INJURED';
        case '병원':
            return 'HOSPITAL';
        case '귀가':
            return 'HOME';
        default:
            return status;
    }
}
