import React from 'react';
import AppRoutes from 'routes/AppRoutes';
import './assets/font/SUIT-Variable.css';

// import "./styles/fonts/SUIT-Variable.css";
//layout
import Layout from 'components/layouts';
function App() {
    return (
        <div className="App">
            <Layout />
            <AppRoutes /> {/* 라우팅 컴포넌트 */}
        </div>
    );
}

export default App;
