const HistoryCard = () => {
    const histories = [
        '갤로핑대학교',
        '갤로핑고등학교',
        '갤로핑중학교',
        '갤로핑초등학교',
        '갤로핑초등학교',
        '갤로핑초등학교',
        '갤로핑초등학교',
        '갤로핑초등학교',
        '갤로핑초등학교',
    ];
    return (
        <div className="w-[453px] h-[360px] bg-primary-white rounded-[10px] p-[30px] flex flex-col gap-[15px]">
            <div className="text-subtitle-medium-bold h-[40px] flex justify-between items-center">
                <div className="text-secondary-dGrayMid">전 소속팀 히스토리</div>
            </div>
            <div className="relative">
                <div className="flex flex-col gap-[10px]  h-[245px] overflow-y-scroll">
                    {histories.map((el, idx) => (
                        <div
                            className="h-[24px] flex items-center"
                            key={idx}
                        >
                            <div
                                className={`${
                                    idx === 0 ? 'border-primary-purple' : 'border-secondary-lGrayMid'
                                } w-[15px] mx-[20px] h-[15px] rounded-full  border-[3px] bg-white z-[1] relative`}
                            ></div>
                            <div>{el}</div>
                        </div>
                    ))}
                </div>
                <div className="absolute border-l-[1px] min-h-[245px] left-[27px] top-0 z-[0] border-[#c8c8c8] border-dashed"></div>
            </div>
        </div>
    );
};
export default HistoryCard;
