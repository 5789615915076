// icons
import { ReactComponent as ArrowRightIcon } from "assets/svgs/arrowRight.svg";

export const areaOptions = {
  // width: '300px', // 전체 너비의 90%로 줄이기
  height: "auto", // 높이를 350에서 300으로 줄이기
  chart: {
    type: "area",
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false, // 드래그 확대 비활성화
    },

    events: {
      // 차트 전체 클릭 이벤트 추가
      click: (event: any, chartContext: any, config: any) => {
        if (config.dataPointIndex !== undefined) {
          const url = `/player/measurement/analysis`;
          window.location.href = `${url}/${config.dataPointIndex}`;
        }
      },
    },
  },
  forecastDataPoints: {},

  stroke: {
    width: 1,
    curve: "straight", // straight
    colors: ["#000000"], // 선 색상 변경
    dashArray: 2, // 점선 제거
  },
  xaxis: {
    type: "date",
    categories: [
      "2024-01-01",
      "2024-01-15",
      "2024-01-16",
      "2024-02-01",
      "2024-02-15",
      "2024-02-18",
      "2024-02-20",
      "2024-03-01",
      "2024-03-05",
    ],
    tooltip: {
      enabled: true,
      // formatter: undefined,
      // offsetY: 0,
      // style: {
      //   background: "#5E6EFF", // 보라색 배경
      //   fontSize: 0,
      //   fontFamily: 0,
      // },
      // // 이 툴팁 디자인을 배경은 보라색, 텍스트는 하얀색으로 바꾸고 싶어. 말풍선 모양이 아닌 그냥 네모로
      // theme: "dark",
    },
    labels: {
      rotate: 0, // 라벨 회전 방지
      formatter: function (val: string) {
        const date = new Date(val);
        const month = String(date.getMonth() + 1).padStart(2, "0"); // 월 두 자리로 표시
        const day = String(date.getDate()).padStart(2, "0"); // 일 두 자리로 표시
        return `${month}.${day}`; // "MM.DD" 형식
      },
      style: {},
    },
  },
  yaxis: {
    min: 0, // 최소값
    max: 100, // 최대값
    tickAmount: 5, // 간격 개수 (0부터 시작해서 5개)
    labels: {
      formatter: (value: number) => value.toString(), // 값 그대로 표시
    },
  },
  fill: {
    type: "gradient", // 단색 설정
    colors: ["#5E6EFF"], // 단색 채우기 색상
    gradient: {
      shade: "dark",
      gradientToColors: ["#B8AFFF"], // 끝 색상 (주황색)
      gradientFromColors: ["#B8AFFF"], // 시작 색상 (주황색)
      shadeIntensity: 1,
      type: "vertical",
      opacityFrom: 0.6, // 시작 투명도
      opacityTo: 0, // 끝 투명도
    },
  },

  title: {
    show: false, // 제목 숨김
  },
  grid: {
    row: {
      opacity: 0.5,
      // colors: ['#ccc234', 'transparent'], // takes an array which will be repeated on columns
    },
    borderColor: "#DCDCDC", // 그리드 선 제거는 transparent
  },
  markers: {
    show: false, // 이걸 커스텀하고싶어
    hover: {
      size: 3, // 호버 시 점 크기 0
    },
    strokeWidth: 3, // 마커 테두리 두께
    strokeColors: ["#5e6eff"], // 보라색 테두리 색상
    fillOpacity: 1, // 내부 채우기 불투명도
    colors: ["#FFFFFF"], // 내부 채우기 색상
    shape: "circle", // 마커 모양
  },
  dataLabels: {
    enabled: false,
    textAnchor: "middle",
    dropShadow: {
      enabled: false, // 드롭 섀도우 비활성화 (잘리는 현상 방지)
    },
    background: {
      enabled: true,
      foreColor: "#ffffff",
      padding: 10,
      borderRadius: 10,
      borderWidth: 1,
      borderColor: "#111111",
      opacity: 1,
    },
    // 호버 시 dataLabels 활성화
    hover: {
      enabled: true,
      show: true,
      onSeries: undefined, // 모든 시리즈에 적용
    },
  },
  events: {
    markerClick: function (
      event: any,
      chartContext: any,
      { seriesIndex, dataPointIndex }: any
    ) {
      const url = `https://player/measurement/anaysis/${seriesIndex}`; // 이동할 URL
      window.location.href = `${url}?point=${dataPointIndex}`;
    },
  },
  tooltip: {
    enabled: true, // 기본 툴팁 완전 비활성화
    shared: false, // 하단 기본 툴팁 비활성화
    custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
      // 툴팁에 표시할 값 가져오기
      const value = series[seriesIndex][dataPointIndex];
      // 툴팁의 x축 좌표
      const xPosition = w.globals.pointsArray[seriesIndex][dataPointIndex];
      const yPosition = w.globals.pointsArray[seriesIndex][dataPointIndex][1];
      const dynamicY = yPosition < 10 ? "25px" : "0px";
      // 그래프 전체 너비
      const chartWidth = w.globals.svgWidth;
      return `
       <div style="
           position: relative; z-index: 10; width: 100%; height:60px; top:-10;
           margin-top: ${dynamicY};
       ">
           <div
           style="
            position:absolute; left:${xPosition[0]}px; zIndex: 10;
            display: flex; align-items: center; gap: 5px;
            border-radius: 20px;
            width:84px; padding: 4px 12px;
            font-size: 14px; font-weight: bold;
            background: #111111; color: white;
           ">
               <span>분석하기</span>
               <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path d="M4.93333 2.7C5.46667 3.1 5.46667 3.9 4.93333 4.3L1.6 6.8C0.940764 7.29443 1.34837e-07 6.82405 1.70857e-07 6L3.89414e-07 1C4.25435e-07 0.175954 0.940764 -0.294427 1.6 0.2L4.93333 2.7Z" fill="white"/>
               </svg>
           </div>
       </div>
       `;
    },
  },
};
export const areaSeries = [
  {
    name: "Sales",
    data: [4, 3, 70, 60, 90, 80, 70, 60, 100],
  },
];
