import { useState } from 'react';
import { DayPicker } from 'react-day-picker'; // SelectSingleEventHandler 추가
import 'react-day-picker/style.css';

interface DatePickerType {
    modalBtn: React.ReactNode;
    selected: string;
    setSelected: (selected: string) => void;
}

const DatePicker = ({ modalBtn, selected, setSelected }: DatePickerType) => {
    const [curDate, setCurDate] = useState<Date>();
    const [isShow, setIsShow] = useState(false);
    const today = new Date();

    // 날짜 선택 핸들러 수정
    const handleSelect = (day: any) => {
        if (day) {
            setCurDate(day);
            const year = day.getFullYear();
            const month = String(day.getMonth() + 1).padStart(2, '0');
            const date = String(day.getDate()).padStart(2, '0');
            // 날짜를 YYYY-MM-DD 형식으로 변환
            setSelected(`${year}-${month}-${date}`);
        }
        setIsShow(false);
    };

    return (
        <div className="relative ">
            <div onClick={() => setIsShow(!isShow)}>{modalBtn}</div>
            {isShow && (
                <div className="absolute z-[10] right-0 p-[10px] rounded-[10px] shadow-md bg-white">
                    <DayPicker
                        mode="single" // 단일 선택 모드 명시
                        captionLayout="dropdown"
                        defaultMonth={today}
                        fromYear={1940}
                        toDate={today}
                        selected={curDate}
                        onSelect={handleSelect}
                        // classNames={{
                        //     caption: 'flex justify-center py-2 mb-4',
                        //     caption_label: 'text-sm font-medium',
                        //     nav: 'flex items-center',
                        //     nav_button: 'h-6 w-6 bg-transparent hover:bg-blue-100 rounded-md',
                        //     nav_button_previous: 'absolute left-1',
                        //     nav_button_next: 'absolute right-1',
                        //     table: 'w-full border-collapse',
                        //     head_row: 'flex font-medium text-sm',
                        //     head_cell: 'text-muted-foreground rounded-md w-8 h-8 flex items-center justify-center',
                        //     row: 'flex w-full mt-2',
                        //     cell: 'text-sm p-0 relative hover:bg-blue-100 rounded-md focus-within:relative focus-within:z-20',
                        //     day: 'h-8 w-8 p-0 font-normal',
                        //     day_selected:
                        //         'bg-blue-500 text-white hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white',
                        //     day_today: 'bg-gray-100',
                        //     day_disabled: 'text-gray-400 hover:bg-transparent',
                        // }}
                    />
                </div>
            )}
        </div>
    );
};

export default DatePicker;
