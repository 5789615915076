// hooks
import { useState } from "react";
// icons
import { ReactComponent as MenuIcon } from "assets/svgs/menu.svg";
import { ReactComponent as SearchIcon } from "assets/svgs/search.svg";
// common
import EmptyBar from "components/emptyBar";
import CustomTable from "components/table/CustomTable";
const Join = () => {
  const colums = [
    { key: "team", label: "학년" },
    { key: "region", label: "배번" },
    { key: "age", label: "포지션" },
    { key: "request", label: "요청하기" },
  ];
  const rows = [
    { team: "갤로핑FC", region: "서울", age: "초등", request: "요청하기" },
    { team: "갤로핑FC", region: "서울", age: "초등", request: "요청하기" },
    { team: "갤로핑FC", region: "서울", age: "초등", request: "요청하기" },
    { team: "갤로핑FC", region: "서울", age: "초등", request: "요청하기" },
    { team: "갤로핑FC", region: "서울", age: "초등", request: "요청하기" },
  ];
  const [hasTeam, setHasTeam] = useState(false);
  return (
    <div className="max-w-[1400px] m-auto">
      <div className="flex">
        <MenuIcon className="text-[#BABABD] w-[30px] h-[30px] mt-[7px] mr-[6px]" />
        <div className="text-primary-white text-title-small-bold">
          <div>안녕하세요 홍길동님,</div>
          <div>축구실력을 높여볼까요?</div>
        </div>
      </div>
      <EmptyBar customStyle="h-[35px]" />
      {!hasTeam ? (
        <div className="bg-primary-white rounded-[10px] p-[30px]">
          <div className="flex justify-between items-center">
            <div className="text-subtitle-medium-bold">
              팀을 검색하고 가입요청을 보내보세요!
            </div>
            <div className="w-[300px]">
              <div className="inputDefaultStyle items-center flex h-[44px] rounded-[8px]">
                <input
                  className="w-[270px] h-[44px] bg-transparent p-[12px] focus:outline-none placeholder:text-primary-black"
                  placeholder="팀 이름을 검색하세요"
                />
                <button>
                  <SearchIcon />
                </button>
              </div>
            </div>
          </div>
          <EmptyBar customStyle="h-[32px]" />
          <CustomTable columns={colums} rows={rows} />
        </div>
      ) : (
        <div className="bg-primary-white flex justify-between rounded-[10px] p-[30px]">
          <div className="gap-[10px] text-subtitle-medium-bold">
            <span className="text-secondary-dGrayMid">현재 소속된 팀은</span>
            <span className="text-primary-purple">[갤로핑FC]</span>
            <span className="text-secondary-dGrayMid">입니다</span>
          </div>
          <button className="w-[80px] h-[44px] rounded-[8px] bg-primary-black text-primary-white text-context-bold ">
            탈퇴하기
          </button>
        </div>
      )}
    </div>
  );
};

export default Join;
