/**
 * 디바운스 함수 - 연속된 호출 중 마지막 호출만 실행
 * @param func 실행할 함수
 * @param delay 지연 시간 (밀리초)
 * @returns 디바운스된 함수
 */
export function debounce<F extends (...args: any[]) => any>(func: F, delay: number): (...args: Parameters<F>) => void {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    return (...args: Parameters<F>) => {
        // 기존 타임아웃 취소
        if (timeoutId !== null) {
            clearTimeout(timeoutId);
        }

        // 새로운 타임아웃 설정
        timeoutId = setTimeout(() => {
            func(...args);
            timeoutId = null;
        }, delay);
    };
}
// const debouncedRefetch = debounce(() => {
//     함수실행();
// }, 300); // 300ms 후 실행
// debouncedRefetch()
