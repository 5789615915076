// hooks
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// assets
import aisoccerWhiteFont from "assets/logos/font-logo/aisoccer-white-font.webp";
// icons
import { ReactComponent as BarChartIcon } from "assets/svgs/barChart.svg";
import { ReactComponent as GroupIcon } from "assets/svgs/group.svg";
import VideoIcon from "assets/svgs/video.png";
import VideoActiveIcon from "assets/svgs/videoActive.png";

const MainSubHeader = () => {
  const location = useLocation().pathname;
  const locataionList = location.split("/");
  const [tab, setTab] = useState(locataionList[2]);
  const navigate = useNavigate();
  const tabList = [
    {
      title: "측정",
      link: "/player/measurement",
      value: "measurement",
      icon: <BarChartIcon />,
    },
    {
      title: "팀가입",
      link: "/player/join",
      value: "join",
      icon: <GroupIcon />,
    },
    {
      title: "추천영상",
      link: "/player/video",
      value: "video",
      icon:
        tab === "video" ? (
          <img src={VideoActiveIcon} alt={"VideoActiveIcon"} />
        ) : (
          <img src={VideoIcon} alt="VideoIcon" />
        ),
    },
  ];
  useEffect(() => {
    if (locataionList[2]) setTab(locataionList[2]);
  }, [locataionList]);
  return (
    <header className="py-[30px] flex justify-between items-center">
      <img
        src={aisoccerWhiteFont}
        alt="aisoccerWhiteFont"
        className="w-[173px]"
      />
      <div className="flex w-[970px] items-center text-secondary-mGrayDark text-subtitle-small-bold gap-[40px]">
        {tabList.map((el) => (
          <button
            key={el.value}
            className={`flex gap-[10px] items-center ${
              el.value === tab
                ? "text-primary-green"
                : "text-secondary-mGrayDark"
            }`}
            onClick={() => navigate(el.link)}
          >
            {el.icon}
            <span className="">{el.title}</span>
          </button>
        ))}
      </div>
    </header>
  );
};

export default MainSubHeader;
