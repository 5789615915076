// hooks
import { useNavigate } from 'react-router-dom';
// assets
import { ReactComponent as RoundedArrowIcon } from 'assets/svgs/roundedArrow.svg';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';

interface MoreNavBtnType {
    type?: 'purple' | 'white';
    navigateUrl?: string;
    activeFunc?: () => void;
    direction?: string;
}

const MoreNavBtn = ({ type = 'white', activeFunc, navigateUrl, direction = 'right' }: MoreNavBtnType) => {
    // const navigate = useNavigate();
    const handleParamNavigate = useParamNavigate();

    const purpleStyle = 'bg-primary-purple text-white';
    const whiteStyle = 'border border-primary-purple text-white';

    return (
        <button
            onClick={() => {
                if (activeFunc) activeFunc();
                if (navigateUrl) handleParamNavigate({}, false, navigateUrl);
            }}
            className={`${
                type === 'white' ? whiteStyle : purpleStyle
            } rounded-full w-[36px] h-[36px] flex justify-center items-center
            ${
                direction === 'right'
                    ? ''
                    : direction === 'left'
                    ? 'rotate-180'
                    : direction === 'up'
                    ? '-rotate-90'
                    : direction === 'down'
                    ? 'rotate-90'
                    : ''
            }
            `}
        >
            <RoundedArrowIcon className={`${type === 'white' ? 'text-primary-purple' : 'text-primary-white'}`} />
        </button>
    );
};

export default MoreNavBtn;
