// hooks
import React, { useEffect, useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// utils
import { seasonData } from 'utils/tmp/staffTmpData';
import { ENDPOINT_MANAGEMENT_PROFILE } from 'utils/consts/apiEndpoint';
import { debounce } from 'utils/debounce';
// commons
import EmptyBar from 'components/emptyBar';
import CustomTable from 'components/table/CustomTable';
import LineUpIdSubHeader from 'pages/staff/control/lineUp/[id]/LineUpIdSubHeader';
import ProfileCard from 'pages/staff/control/lineUp/[id]/ProfileCard';
import InjuryCard from 'pages/staff/control/lineUp/[id]/InjuryCard';
import MoreNavBtn from 'components/button/MoreNavBtn';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const LineUpId = () => {
    const { staffRole } = useSelector((state: RootState) => state.userInfo);
    const locationList = useLocation().pathname.split('/');
    const [searchParams] = useSearchParams();
    const paramPlayerId = searchParams.get('player') || '';
    const paramAgeGroup = searchParams.get('ageGroup') || '';
    const [moreSeasonData, setMoreSeasonData] = useState(false);
    const [dropdownPlayerOpts, setDropdownPlayerOpts] = useState<PlayerDropdownOptsType[]>([]);
    // setDropdownPlayerOpts(handlePlayerDropdownOpts(players));

    const columns = [
        { key: 'matchTitle', label: '경기명' },
        { key: 'match', label: '경기' },
        { key: 'goal', label: '골', sortable: false },
        { key: 'assist', label: '도움' },
        { key: 'average', label: '평균 평점', sortable: false },
    ];

    // react useApiQuery
    const getManagementPlayerProfiles = useApiQuery(
        ['getManagementPlayerProfiles', paramPlayerId, paramAgeGroup],
        ENDPOINT_MANAGEMENT_PROFILE.season.players(`${locationList[4]}`, {
            ageGroup: staffRole === 'OFFICE' && paramAgeGroup ? paramAgeGroup : undefined,
        }),
        'get'
    );
    const getPlayerProfile = useApiQuery(
        ['getPlayerProfile', paramPlayerId],
        ENDPOINT_MANAGEMENT_PROFILE.player(paramPlayerId),
        'get'
    );

    useEffect(() => {
        if (getManagementPlayerProfiles.isSuccess && getManagementPlayerProfiles.data) {
            const { players } = getManagementPlayerProfiles?.data;
            setDropdownPlayerOpts(handlePlayerDropdownOpts(players));
        }
    }, [getManagementPlayerProfiles.isSuccess, getManagementPlayerProfiles.data]);

    useEffect(() => {
        if (paramPlayerId && !getPlayerProfile?.isLoading) {
            // 사용 예시
            const debouncedRefetch = debounce(() => {
                getPlayerProfile.refetch(); // refetch를 지연시킴
            }, 300); // 300ms 후 실행

            debouncedRefetch(); // 이 함수가 실행되는 부분
        }
    }, [paramPlayerId]);

    return (
        <div className="w-[1400px] m-auto">
            <LineUpIdSubHeader
                playerInfo={getPlayerProfile?.data?.playerInfo}
                seasonStats={getPlayerProfile?.data?.seasonStats}
                dropdownPlayerOpts={dropdownPlayerOpts}
            />
            <EmptyBar customStyle={'h-[35px]'} />
            <div className="flex gap-[20px] relative">
                <ProfileCard playerInfo={getPlayerProfile?.data?.playerInfo} />
                {/* 시즌통계 */}
                <div
                    className={`w-full bg-white max-w-[690px]  rounded-[10px] p-[30px] flex flex-col gap-[20px]
                ${moreSeasonData ? 'h-[760px] absolute right-0' : 'h-[370px]'}
                `}
                >
                    <div className="flex items-center justify-between">
                        <div className="flex items-cent first-line:er gap-[10px]">
                            <div className="text-subtitle-medium-bold text-secondary-dGrayMid">시즌통계</div>
                            <MoreNavBtn
                                type="white"
                                direction={moreSeasonData ? 'up' : 'down'}
                                activeFunc={() => setMoreSeasonData(!moreSeasonData)}
                            />
                        </div>

                        <div className="flex gap-[10px] items-center">
                            <div className="flex gap-[15px]">
                                <span className="text-secondary-lGrayMid">출장</span>
                                <span className="text-secondary-dGrayMid">
                                    {getPlayerProfile?.data?.seasonStats?.totalStats?.totalAppearances}
                                </span>
                                <span className="text-secondary-lGrayMid">평점</span>
                                <span className="text-secondary-dGrayMid">
                                    {' '}
                                    {getPlayerProfile?.data?.seasonStats?.totalStats?.totalAverageRating}
                                </span>
                                <span className="text-secondary-lGrayMid">골</span>
                                <span className="text-secondary-dGrayMid">
                                    {getPlayerProfile?.data?.seasonStats?.totalStats?.totalGoals}
                                </span>
                                <span className="text-secondary-lGrayMid">도움</span>
                                <span className="text-secondary-dGrayMid">
                                    {getPlayerProfile?.data?.seasonStats?.totalStats?.totalAssists}
                                </span>
                            </div>
                            <MoreNavBtn
                                type="purple"
                                navigateUrl="/staff/control/lineUp/1/season"
                            />
                        </div>
                    </div>
                    <div className="w-full ">
                        {/* overflow-y-scroll */}
                        <CustomTable
                            columns={columns} // getPlayerProfile?.data?.seasonStats?.totalStats.competitionStats
                            rows={moreSeasonData ? seasonData : seasonData.slice(0, 4)}
                            equalWidth={true}
                            minWidth={'min-w-[116px]'}
                        />
                    </div>
                </div>
            </div>
            <EmptyBar customStyle={'h-[20px]'} />
            <div className="flex gap-[20px]">
                <InjuryCard injuryInfo={getPlayerProfile?.data?.injuryInfo} />
                {/* 경력정보 */}
                <div className="w-full bg-white max-w-[690px] h-[370px] rounded-[10px] p-[30px] flex flex-col gap-[20px]">
                    <div className="flex items-center justify-between">
                        <div className="text-subtitle-medium-bold text-secondary-dGrayMid">경력정보</div>

                        <MoreNavBtn
                            type="purple"
                            navigateUrl="/staff/control/lineUp/1/career"
                        />
                    </div>
                    <div className="w-full">
                        {/* overflow-y-scroll */}
                        <CustomTable
                            columns={columns}
                            rows={seasonData.slice(0, 4)}
                            equalWidth={true}
                            minWidth={'min-w-[116px]'}
                        />
                    </div>
                </div>
            </div>

            <EmptyBar customStyle={'h-[20px]'} />
        </div>
    );
};

export default LineUpId;

export const handlePlayerDropdownOpts = (rows: any[]) => {
    return rows.map((row) => ({
        value: row.playerId,
        label: `${row.uniformNumber}. ${row.name}`,
    }));
};

interface PlayerDropdownOptsType {
    value: string;
    label: string;
}
