// hooks
import React from 'react';
// icons
import { ReactComponent as TriangleIcon } from 'assets/svgs/triangle.svg';

type StatusLabelProps = {
    status: 'up' | 'down' | null;
    children: React.ReactNode;
    className?: string;
    customColor?: string;
};

const StatusLabel: React.FC<StatusLabelProps> = ({ status, children, className = '', customColor }) => {
    return (
        <div
            className={`
                inline-flex
                items-center
                ${
                    customColor
                        ? customColor
                        : status === 'up'
                        ? 'bg-[#5E6EFF] text-primary-white'
                        : status === 'down'
                        ? 'bg-[#D2D2D2] text-[#111111]'
                        : 'bg-[#D2D2D2] text-[#111111]'
                }
                rounded-[80px]
                px-[12px]
                py-[4px]
                text-label-bold
                ${className}
            `}
        >
            {children}
            {status === 'up' ? (
                <TriangleIcon
                    className="w-[16.45px] rotate-180"
                    color="currentColor"
                />
            ) : status === 'down' ? (
                <TriangleIcon
                    className="w-[16.45px]"
                    color="currentColor"
                />
            ) : (
                <></>
            )}
        </div>
    );
};

export default StatusLabel;
// 사용방법
{
    /* <StatusLabel status="up">
10.5%
</StatusLabel> */
}

{
    /* Down 상태 */
}
{
    /* <StatusLabel status="down">
5.3%
</StatusLabel> */
}
