import React, { useState, useEffect } from "react";
// commons
import EmptyBar from "components/emptyBar";
import CustomTable from "components/table/CustomTable";
import Dropdown from "components/dropdown/Default";
// utils
import { useParamNavigate } from "hooks/useDynamicNavigate";
// staff components
import ScheduleSubHeader from "pages/staff/control/schedule/ScheduleSubHeader";
// icons
import { ReactComponent as EditPenIcon } from "assets/svgs/editPen.svg";
const Schedule = () => {
  const handleParamNavigate = useParamNavigate();
  const columns = [
    { key: "matchDate", label: "경기 날짜", sortable: true },
    { key: "type", label: "유형", sortable: true },
    { key: "tournamentName", label: "대회/리그 이름" },
    { key: "opponentTeam", label: "상대팀", sortable: false },
    { key: "result", label: "경기결과", sortable: true },
    { key: "edit", label: "수정", sortable: false },
  ];

  return (
    <div className="w-[1400px] m-auto">
      <ScheduleSubHeader />
      <EmptyBar customStyle={"h-[35px]"} />

      <div className="w-full  bg-white rounded-[10px] p-[30px] ">
        <div className="flex gap-[20px] items-center justify-between">
          <div className="flex gap-[5px] text-[20px] font-semibold">
            <span className="text-secondary-dGrayMid text-subtitle-medium-bold">
              2024 일정
            </span>
          </div>
          <button className="bg-primary-purple rounded-[8px] text-context-bold text-primary-white w-[98px] h-[44px] ">
            리포트 작성
          </button>
        </div>
        <EmptyBar customStyle={"h-[20px]"} />

        <div className="w-full h-fit ">
          <CustomTable
            columns={columns}
            rows={rows}
            equalWidth={false}
            minWidth={"min-w-[116px]"}
          />
        </div>
      </div>

      <EmptyBar customStyle={"h-[20px]"} />
    </div>
  );
};

export default Schedule;

export const rows = [
  {
    matchDate: "2024.12.30",
    type: "리그",
    tournamentName: "MBC",
    opponentTeam: "전북 U-15",
    result: "1:0(승)",
    edit: (
      <button>
        <EditPenIcon className="text-secondary-lGrayDark" />
      </button>
    ),
  },
  {
    matchDate: "2024.12.30",
    type: "리그",
    tournamentName: "MBC",
    opponentTeam: "전북 U-15",
    result: "1:0(승)",
    edit: (
      <button>
        <EditPenIcon className="text-secondary-lGrayDark" />
      </button>
    ),
  },
  {
    matchDate: "2024.12.30",
    type: "리그",
    tournamentName: "MBC",
    opponentTeam: "전북 U-15",
    result: "1:0(승)",
    edit: (
      <button>
        <EditPenIcon className="text-secondary-lGrayDark" />
      </button>
    ),
  },
  {
    matchDate: "2024.12.30",
    type: "리그",
    tournamentName: "MBC",
    opponentTeam: "전북 U-15",
    result: "1:0(승)",
    edit: (
      <button>
        <EditPenIcon className="text-secondary-lGrayDark" />
      </button>
    ),
  },
  {
    matchDate: "2024.12.30",
    type: "리그",
    tournamentName: "MBC",
    opponentTeam: "전북 U-15",
    result: "1:0(승)",
    edit: (
      <button>
        <EditPenIcon className="text-secondary-lGrayDark" />
      </button>
    ),
  },
  {
    matchDate: "2024.12.30",
    type: "리그",
    tournamentName: "MBC",
    opponentTeam: "전북 U-15",
    result: "1:0(승)",
    edit: (
      <button>
        <EditPenIcon className="text-secondary-lGrayDark" />
      </button>
    ),
  },
  {
    matchDate: "2024.12.30",
    type: "리그",
    tournamentName: "MBC",
    opponentTeam: "전북 U-15",
    result: "1:0(승)",
    edit: (
      <button>
        <EditPenIcon className="text-secondary-lGrayDark" />
      </button>
    ),
  },
  {
    matchDate: "2024.12.30",
    type: "리그",
    tournamentName: "MBC",
    opponentTeam: "전북 U-15",
    result: "1:0(승)",
    edit: (
      <button>
        <EditPenIcon className="text-secondary-lGrayDark" />
      </button>
    ),
  },
  {
    matchDate: "2024.12.30",
    type: "리그",
    tournamentName: "MBC",
    opponentTeam: "전북 U-15",
    result: "1:0(승)",
    edit: (
      <button>
        <EditPenIcon className="text-secondary-lGrayDark" />
      </button>
    ),
  },
  {
    matchDate: "2024.12.30",
    type: "리그",
    tournamentName: "MBC",
    opponentTeam: "전북 U-15",
    result: "1:0(승)",
    edit: (
      <button>
        <EditPenIcon className="text-secondary-lGrayDark" />
      </button>
    ),
  },
  {
    matchDate: "2024.12.30",
    type: "리그",
    tournamentName: "MBC",
    opponentTeam: "전북 U-15",
    result: "1:0(승)",
    edit: (
      <button>
        <EditPenIcon className="text-secondary-lGrayDark" />
      </button>
    ),
  },
];
