export const nameVerifyFunc = (name: string) => {
    const nameRegex = /^[가-힣]{2,10}$/;
    if (nameRegex.test(name)) return true;
    else return false;
};

export const phoneVerifyFunc = (phone: string) => {
    const phoneRegex = /^\d{3}-\d{3,4}-\d{4}$/;
    if (phoneRegex.test(phone)) return true;
    else return false;
};

export const emailVerifyFunc = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(email)) return true;
    else return false;
};

export const pwVerifyFunc = (email: string) => {
    const pwRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W_]{8,15}$/;
    if (pwRegex.test(email)) return true;
    else return false;
};
