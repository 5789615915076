import { useEffect, useState } from 'react';
import { ReactComponent as MenuIcon } from 'assets/svgs/menu.svg';
// common
import StatusLabel from 'components/label/Default';
import Dropdown from 'components/dropdown/Default';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';

const RatingSubHeader = () => {
    const handleParamNavigate = useParamNavigate();

    const options = [
        { value: 'U12', label: 'U12' },
        { value: 'U15', label: 'U15' },
        { value: 'U18', label: 'U18' },
    ];
    const [youthRange, setYouthRange] = useState('U12');
    useEffect(() => {
        if (youthRange)
            handleParamNavigate({
                ageGroup: youthRange,
            });
    }, [youthRange]);

    return (
        <div className="h-[80px]">
            <div className="flex items-center justify-between">
                <div className="flex gap-[14px] items-center">
                    <MenuIcon className="text-[#BABABD] w-[30px] h-[30px]  mr-[6px]" />
                    <div className="text-primary-white text-title-small-bold">
                        <div>갤로핑 FC 선수단</div>
                    </div>
                    <Dropdown
                        options={options}
                        value={youthRange}
                        defaultValue={'U12'}
                        onChange={setYouthRange}
                        className={'w-[82px] border rounded-[8px] border-primary-white'}
                    />
                </div>
                <div className="flex gap-[80px] ">
                    <div className=" flex gap-[18px]  h-[48px] text-[#868686]">
                        <div className="w-[5px] bg-primary-green rounded-[5px]"></div>
                        <div className="flex gap-[80px] items-center">
                            <div
                                className="w-[68px] text-primary-white font-semibold leading-[24px]"
                                style={{ wordBreak: 'keep-all', overflowWrap: 'break-word' }} //한국어 띄어쓰기 단위로 줄바꿈
                            >
                                {'2024년 1차'}
                            </div>
                            <div className="flex items-center gap-[36px]">
                                <div className="flex items-center gap-[10px]">
                                    <span className="text-[22px]">{'평균 등급'}</span>
                                    <span className="text-[50px] text-primary-green font-bold">{60}</span>
                                </div>
                                <div className="flex items-center gap-[10px]">
                                    <span className="text-[22px]">{'리그 출전'}</span>
                                    <span className="text-[50px] text-primary-white font-bold">{7.67}</span>
                                </div>
                                <div className="flex items-center gap-[10px]">
                                    <span className="text-[22px]">{'평가 완료'}</span>
                                    <span className="text-[50px] text-primary-white font-bold">{1}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RatingSubHeader;
