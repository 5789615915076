// admin components
import TeamControl from 'pages/admin/userControl/teamControl';
import PlayerControl from 'pages/admin/userControl/playerControl';
// common style
import Tabs from 'components/tab/Default';

import { useState } from 'react';
const UserControl = () => {
    const [tab, setTab] = useState(0);
    return (
        <div>
            <Tabs
                tabs={userTab}
                defaultActiveTab={0}
                onTabChange={(index) => setTab(index)}
            />
            {tab === 0 ? <PlayerControl /> : <TeamControl />}
        </div>
    );
};

export default UserControl;

export const userTab = [
    {
        label: '선수관리',
    },
    {
        label: '팀관리',
    },
];
