// hooks
import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
//icons
import { ReactComponent as MenuIcon } from 'assets/svgs/menu.svg';
// common
import Dropdown from 'components/dropdown/Default';
import MoreNavBtn from 'components/button/MoreNavBtn';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { ENDPOINT } from 'utils/consts/apiEndpoint';
import { dropDownFormChanger, dropDownFormChangerType } from 'utils/formChanger';
import { ENDPOINT_MANAGEMENT_PROFILE } from 'utils/consts/apiEndpoint';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const LineUpIdSubNavHeader = () => {
    const { staffRole } = useSelector((state: RootState) => state.userInfo);
    const navigate = useNavigate();
    const location = useLocation().pathname;
    const locationList = location.split('/');
    const [searchParams] = useSearchParams();
    const paramAgeGroup = searchParams.get('ageGroup') || '';
    const paramPlayerId = searchParams.get('player') || '';

    const handleParamNavigate = useParamNavigate();

    const childRouters = [
        { value: 'career', label: '경력정보' },
        { value: 'injury', label: '부상정보' },
        { value: 'season', label: '시즌통계' },
    ];
    const [player, setPlayer] = useState('');
    const [dropdownPlayerOpts, setDropdownPlayerOpts] = useState<PlayerDropdownOptsType[]>([]);
    const [teamName, setTeamName] = useState('TEAM');
    const [ageGroup, setAgeGroup] = useState('');
    const [ageGroupOpts, setAgeGroupsOpts] = useState<dropDownFormChangerType[]>([]);

    const [childRouter, setChildRouter] = useState(locationList[5]);
    const handleNavigate = (router: string) => {
        navigate(`/staff/control/lineUp/${locationList[5]}/${router}`);
    };

    // react useApiQuery
    const getAgeGroup = useApiQuery(['ageGroup'], ENDPOINT.staff.team.ageGroups, 'get');
    const getManagementPlayerProfiles = useApiQuery(
        ['getManagementPlayerProfiles', paramAgeGroup],
        ENDPOINT_MANAGEMENT_PROFILE.season.players(`${locationList[4]}`, {
            ageGroup: staffRole === 'OFFICE' && paramAgeGroup ? paramAgeGroup : undefined,
        }),
        'get'
    );
    const getPlayerProfile = useApiQuery(
        ['getPlayerProfile', paramPlayerId],
        ENDPOINT_MANAGEMENT_PROFILE.player(paramPlayerId),
        'get'
    );

    useEffect(() => {
        if (getAgeGroup?.isSuccess && getAgeGroup?.data) {
            const { teamName, ageGroups } = getAgeGroup?.data;
            setTeamName(teamName);
            setAgeGroup(ageGroups[0]);
            const newAgeGroupOpts = dropDownFormChanger(ageGroups);
            setAgeGroupsOpts(newAgeGroupOpts);
        }
    }, [getAgeGroup?.data]);

    useEffect(() => {
        if (getManagementPlayerProfiles.isSuccess && getManagementPlayerProfiles.data) {
            const { players } = getManagementPlayerProfiles?.data;
            setDropdownPlayerOpts(handlePlayerDropdownOpts(players));
        }
    }, [getManagementPlayerProfiles.isSuccess, getManagementPlayerProfiles.data]);

    useEffect(() => {
        if (ageGroup && player)
            handleParamNavigate({
                ageGroup: ageGroup,
                player: player,
            });
    }, [player]);

    return (
        <div className="h-[80px] ">
            <div className="flex items-center justify-between">
                <div className="flex gap-[14px] items-center">
                    <MenuIcon className="text-[#BABABD] w-[30px] h-[30px] mr-[6px]" />
                    <div className="text-primary-white text-title-small-bold">
                        <div>{teamName}</div>
                    </div>
                    <div className="gap-[7px] flex w-[420px]">
                        <Dropdown
                            options={ageGroupOpts}
                            value={ageGroup}
                            placeholder="연령"
                            onChange={setAgeGroup}
                            className={'w-[82px] border rounded-[8px] border-primary-white'}
                        />
                        <Dropdown
                            options={dropdownPlayerOpts}
                            value={player}
                            placeholder={
                                getPlayerProfile?.data?.playerInfo?.uniformNumber
                                    ? `${getPlayerProfile?.data?.playerInfo?.uniformNumber}. ${getPlayerProfile?.data?.playerInfo?.name}`
                                    : '선수명'
                            }
                            onChange={setPlayer}
                            className={'border w-fit rounded-[8px] border-primary-white'}
                        />
                        <Dropdown
                            options={childRouters}
                            value={childRouter}
                            defaultValue={childRouter[5]}
                            activeFunc={handleNavigate}
                            onChange={setChildRouter}
                            className={'border rounded-[8px] border-primary-white'}
                        />
                    </div>
                </div>
                {childRouter === 'season' && (
                    <div className="flex gap-[18px]  h-[48px] text-[#868686]">
                        <div className="w-[5px] bg-primary-green rounded-[5px]"></div>

                        <div className="flex gap-[15px] items-center">
                            <div className="flex items-center gap-[35px] ">
                                <div className="flex items-center gap-[5px]">
                                    <span className="text-[22px] ">{'출장'}</span>
                                    <span className="text-[50px] text-primary-green font-bold">{60}</span>
                                </div>
                                <div className="flex items-center gap-[5px]">
                                    <span className="text-[22px]">{'평점'}</span>
                                    <span className="text-[50px] text-primary-white font-bold">{7.67}</span>
                                </div>
                                <div className="flex items-center gap-[5px]">
                                    <span className="text-[22px]">{'골'}</span>
                                    <span className="text-[50px] text-status-error font-bold">{8}</span>
                                </div>
                                <div className="flex items-center gap-[5px]">
                                    <span className="text-[22px]">{'도움'}</span>
                                    <span className="text-[50px] text-primary-white font-bold">{20}</span>
                                </div>
                                {/* <MoreNavBtn navigateUrl="/staff/control/lineUp/schedule" /> */}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LineUpIdSubNavHeader;

export const handlePlayerDropdownOpts = (rows: any[]) => {
    return rows.map((row) => ({
        value: row.playerId,
        label: `${row.uniformNumber}. ${row.name}`,
    }));
};

interface PlayerDropdownOptsType {
    value: string;
    label: string;
}
