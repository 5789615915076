// hooks
import { useState } from 'react';
// apexChart
import ApexChartExample from 'components/chart/ApexChartExample';
import { radarOptions, radarSeries } from './radarOpt';
import { barOptions, barSeries } from './barOpt';
import { areaOptions, areaSeries } from './areaOpt';
// commons
import Footer from 'components/footer/Default';
import MainSubHeader from 'pages/player/components/MainSubHeader';
import EmptyBar from 'components/emptyBar';
import Dropdown from 'components/dropdown/Default';
import Tabs from 'components/tab/Default';
import StatusLabel from 'components/label/Default';
import { SquareRadio, RadioGroup } from 'components/radio/SquareRadio';
import { ReactComponent as ArrowIcon } from 'assets/svgs/arrow.svg';

// utils
import { skills, skillTab } from 'utils/consts/measurement';

const MeasurementMain = () => {
    const [skillCategory, setSkillCategory] = useState(skills[0]?.value);
    const [dribbleType, setDribbleType] = useState('드리블1');
    const detailSkills = [
        { title: '잔발', score: 90.5, growth: 55 },
        { title: '상체 밸런스', score: 86.5, growth: -3 },
        { title: '상체 밸런스', score: 86.5, growth: -3 },
        { title: '상체 밸런스', score: 86.5, growth: -3 },
        { title: '상체 밸런스', score: 86.5, growth: -3 },
        { title: '상체 밸런스', score: 86.5, growth: -3 },
        { title: '상체 밸런스', score: 86.5, growth: -3 },
    ];
    const [selectedDetailSkill, setSelectedDetailSkill] = useState('잔발');

    return (
        <div className="w-[1400px] m-auto">
            <MainSubHeader />
            <EmptyBar customStyle={'h-[35px]'} />

            <div className="flex flex-col gap-[1rem] ">
                <div className="flex justify-between w-full">
                    <div
                        id="main-area-chart"
                        className="w-[927px] h-[360px] bg-white rounded-[10px] flex"
                    >
                        <div className="text-subtitle-medium-bold text-[20px] pl-[30px] py-[30px] shrink-0">
                            종합측정
                        </div>
                        <div className="p-[12px] w-[830px]  flex justify-center items-center mt-[5px]">
                            <ApexChartExample
                                customType={'area'}
                                customOptions={areaOptions}
                                customSeries={areaSeries}
                                height={314}
                            />
                        </div>
                    </div>
                    <div className="relative w-[453px] h-[360px] bg-white rounded-[10px] flex justify-center items-center">
                        <div className="p-[30px] absolute z-10 top-0  left-0 w-[453px] flex justify-between items-center">
                            <span className="text-subtitle-medium-bold">비교측정</span>
                            <span>
                                <Dropdown
                                    options={skills}
                                    value={skillCategory}
                                    onChange={setSkillCategory}
                                />
                            </span>
                        </div>
                        <div
                            id="main-radar-chart"
                            className="w-[430px] flex justify-center ml-[20px]"
                        >
                            <ApexChartExample
                                customType={'radar'}
                                customOptions={radarOptions}
                                customSeries={radarSeries}
                                height={375}
                            />
                        </div>
                    </div>
                </div>
                <div className=" bg-white rounded-[10px] mb-[15px] h-[434px]">
                    <div className="text-subtitle-medium-bold text-[20px] pl-[30px] pr-[30px] pt-[30px] pb-[20px] shrink-0 flex justify-between items-center">
                        <span>기술별 점수</span>
                        <Tabs
                            tabs={skillTab}
                            defaultActiveTab={0}
                            onTabChange={(index) => console.log(`Selected tab: ${index}`)}
                        />
                    </div>
                    <div className="flex justify-between">
                        <div
                            id="main-bar-chart"
                            className="w-[800px] pl-[15px] mt-[-10px]"
                        >
                            <ApexChartExample
                                customType={'bar'}
                                customOptions={barOptions}
                                customSeries={barSeries}
                                height={318}
                            />
                        </div>
                        <div className="w-[550px] mr-[30px]">
                            <div className="flex justify-between mb-[20px]">
                                <div className="flex items-center gap-[6px] text-secondary-lGrayDark ">
                                    <span>기술별</span> <ArrowIcon className="-rotate-90" />
                                    <span>드리블</span>
                                    <ArrowIcon className="-rotate-90" />
                                    <span className="text-context-bold text-primary-black">잔발</span>
                                </div>
                                <RadioGroup
                                    value={dribbleType}
                                    onChange={setDribbleType}
                                >
                                    <SquareRadio
                                        value="드리블1"
                                        label="드리블1"
                                    />
                                    <SquareRadio
                                        value="드리블2"
                                        label="드리블2"
                                    />
                                </RadioGroup>
                            </div>
                            <div className="h-[260px] grid grid-cols-3 overflow-y-scroll gap-[12px]">
                                {detailSkills.map((el) => (
                                    <div
                                        className={`w-full h-[260px]  flex flex-col justify-center items-center  ${
                                            selectedDetailSkill === el.title
                                                ? 'rounded-[14px] border-2 border-primary-purple'
                                                : 'bg-[#F8F8F8] rounded-[8px]'
                                        }`}
                                    >
                                        <div className="w-[40px]  mx-auto h-[40px] rounded-[6px] bg-primary-black"></div>
                                        <div className="text-center my-[12px] text-context-regular">{el.title}</div>
                                        <div className="text-title-medium-bold text-center mb-[12px]">{el.score}</div>
                                        <StatusLabel status={el.growth > 0 ? 'up' : 'down'}>
                                            {`${el?.growth} %`}
                                        </StatusLabel>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EmptyBar customStyle="h-[20px]" />
            <Footer />
        </div>
    );
};

export default MeasurementMain;
