import { useEffect, useState } from 'react';

// commons
import EmptyBar from 'components/emptyBar';
import CustomTable from 'components/table/CustomTable';
import RatingIdSubHeader from 'pages/staff/control/rating/[id]/RatingIdSubHeader';
import ProfileCard from 'pages/staff/control/rating/[id]/ProfileCard';
import HistoryCard from 'pages/staff/control/rating/[id]/HistoryCard';
import RatingCard from 'pages/staff/control/rating/[id]/RatingCard';
// icons
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';

const RatingId = () => {
    const [isEdit, setIsEdit] = useState(true);
    const columns = [
        { key: 'matchTitle', label: '경기명' },
        { key: 'match', label: '경기' },
        { key: 'goal', label: '골', sortable: true },
        { key: 'assist', label: '도움' },
        { key: 'average', label: '평균 평점', sortable: true },
    ];

    return (
        <div className="w-[1400px] m-auto">
            <RatingIdSubHeader />
            <EmptyBar customStyle={'h-[35px]'} />
            <div className="flex gap-[20px]">
                <ProfileCard />
                <HistoryCard />
            </div>
            <EmptyBar customStyle={'h-[20px]'} />
            <div className="w-full bg-white max-h-[690px] rounded-[10px] p-[30px] flex flex-col gap-[20px]">
                <div className="flex items-center justify-between">
                    <div className="text-subtitle-medium-bold text-secondary-dGrayMid">분석 및 평가</div>
                    {isEdit ? (
                        <button
                            onClick={() => setIsEdit(!isEdit)}
                            className="w-[84px] h-[44px] bg-primary-purple rounded-[10px] text-primary-white text-context-bold"
                        >
                            평가 반영
                        </button>
                    ) : (
                        <button
                            onClick={() => setIsEdit(!isEdit)}
                            className="text-primary-purple"
                        >
                            <EditPenIcon />
                        </button>
                    )}
                </div>
                <div className="w-full ">
                    <RatingCard isEdit={isEdit} />
                </div>
            </div>

            <EmptyBar customStyle={'h-[20px]'} />
        </div>
    );
};

export default RatingId;
