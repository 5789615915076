// hooks
import { useEffect, useState } from 'react';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
// redux
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { setUserInfoState } from 'store/userInfoSlice';
// common
import SearchMap from 'components/map/SearchMap';
import DatePicker from 'components/datePicker';
import { MarkerType } from 'components/map/SearchMap';
import { showToast } from 'components/toast/Default';
// utils
import { handleTelValue } from 'utils/inputHandleFunc/handleTelValue';
import { ENDPOINT } from 'utils/consts/apiEndpoint';
import { nameVerifyFunc, phoneVerifyFunc } from 'utils/verifyFunc/userInfoVerify';
import { regionMatcher } from 'utils/matchers/userInfoMatcher';
import { generOptions, positionOptions } from 'utils/consts/userInfo';
// icons
import { ReactComponent as LFootIcon } from 'assets/svgs/lFoot.svg';
import { ReactComponent as RFootIcon } from 'assets/svgs/rFoot.svg';
import { ReactComponent as SearchIcon } from 'assets/svgs/search.svg';
import { ReactComponent as CalendarIcon } from 'assets/svgs/calendar.svg';

const PlayerInfoForm = () => {
    const dispatch = useDispatch();
    const {
        name,
        id,
        email,
        profileImageUrl,
        teamNames,
        role, // null 허용
        staffRole, // 이미 null 허용됨
        status,
    } = useSelector((state: RootState) => state.userInfo);
    useEffect(() => {
        if (name)
            setNewName({
                value: name,
                state: 'default',
            });
    }, [name]);
    // 선수 state
    const [newName, setNewName] = useState({
        value: name || '',
        state: 'default',
    });
    const [parentPhone, setParentPhone] = useState({
        value: '',
        state: 'default',
    });
    const [address, setAddress] = useState<MarkerType | undefined>();
    const [birth, setBirth] = useState<string>('');
    const [gender, setGenter] = useState('');
    const [height, setHeight] = useState<number | undefined>();
    const [weight, setWeight] = useState<number | undefined>();
    const [uniformNumber, setUniformNumber] = useState<number | undefined>();
    const [position, setPosition] = useState(['GK']); // 배열로 전달해야함!
    const [majorFoot, setMajorFoot] = useState('');
    const [selectedFoots, setSelectedFoots] = useState({ left: false, right: false });
    const [region, setRegion] = useState('');
    // ReactQuery Mutation
    const postPlayerProfile = useApiMutation(ENDPOINT.user.profile, 'post', {
        onSuccess: (data) => {
            dispatch(
                setUserInfoState({
                    name: data.name,
                    id,
                    email,
                    profileImageUrl,
                    teamNames,
                    role, // null 허용
                    staffRole, // 이미 null 허용됨
                    status,
                })
            );
            showToast('선수 프로필 등록이 완료되었습니다.', 'success');
        },
        onError: (error) => {
            // 에러 메시지 추출
            showToast(`프로필등록 실패: ${error}`, 'error');
        },
    });

    const handleAddress = (address: MarkerType) => {
        const splittedAddress = address?.road_address_name?.split(' ');
        setRegion(regionMatcher(splittedAddress[0]));
        setAddress(address);
    };
    const handleMajorFoot = (foot: 'left' | 'right') => {
        setSelectedFoots((prev) => {
            const newSelected = {
                ...prev,
                [foot]: !prev[foot], // 클릭 시 상태 토글
            };
            // 상태에 따라 majorFoot 값 설정
            if (newSelected.left && newSelected.right) {
                setMajorFoot('BOTH');
            } else if (newSelected.left) {
                setMajorFoot('LEFT');
            } else if (newSelected.right) {
                setMajorFoot('RIGHT');
            } else {
                setMajorFoot('');
            }

            return newSelected;
        });
    };

    // submit func
    const submitProfile = () => {
        // 이름, 전화번호 외의 다른 항목들도 regex 유효검사 제작 필요함
        if (!nameVerifyFunc(newName?.value)) {
            showToast(`잘못된 이름입니다.`, 'error');
            setNewName((prev) => ({ ...prev, state: 'error' }));
            return;
        } else if (!phoneVerifyFunc(parentPhone?.value)) {
            showToast(`번호를 정확히 입력해주세요.`, 'error');
            setParentPhone((prev) => ({ ...prev, state: 'error' }));
            return;
        } else if (!gender) {
            showToast(`성별을 선택해주세요.`, 'error');
            return;
        } else if (!region) {
            showToast(`지역을 선택해주세요.`, 'error');
            return;
        } else if (!address) {
            showToast(`주소를 입력해주세요.`, 'error');
            return;
        } else if (!birth) {
            showToast(`생년월일을 입력해주세요.`, 'error');
            return;
        } else if (!height || !(Number(height) < 999.9)) {
            showToast(`키를 정확히 입력해주세요.`, 'error');
            return;
        } else if (!weight || !(Number(weight) < 999.9)) {
            showToast(`몸무게를 정확히 입력해주세요.`, 'error');
            return;
        } else if (position.length < 1) {
            showToast(`포지션을 1개 이상 입력해주세요.`, 'error');
            return;
        } else if (!uniformNumber) {
            showToast(`등번호를 입력해주세요.`, 'error');
            return;
        } else if (!majorFoot) {
            showToast(`주 발을 선택해주세요.`, 'error');
            return;
        }

        postPlayerProfile.mutate({
            name: newName.value,
            phoneParent: parentPhone?.value.replace(/-/g, ''),
            address: address?.road_address_name,
            birthday: birth,
            gender: gender,
            height: height,
            weight: weight,
            uniformNumber: uniformNumber,
            positions: position,
            majorFoot: majorFoot,
            region: region,
        });
    };

    return (
        <div
            className="flex flex-col gap-[14px]  p-[5px] overflow-y-scroll"
            // style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' /* for IE */ }}
        >
            <div className="flex justify-between">
                <div className=" text-secondary-lGrayDark text-context-bold">성별</div>
                <div className="flex items-center gap-[14px]">
                    {generOptions.map((el) => (
                        <label
                            className="h-[24px] flex items-center gap-[14px]"
                            onClick={() => setGenter(el.value)}
                        >
                            <div
                                className={` relative text-secondary-lGrayDark w-[24px] h-[24px] rounded-full border flex items-center justify-center transition-all duration-200 ${
                                    el.value === gender
                                        ? 'border-2 border-primary-purple bg-primary-white'
                                        : 'border-primary-black bg-primary-white'
                                } `}
                            >
                                {el.value === gender && (
                                    <div className="absolute w-[12px] h-[12px] rounded-full bg-primary-purple" />
                                )}
                            </div>
                            {el.label}
                        </label>
                    ))}
                </div>
            </div>
            <input
                type="name"
                value={newName.value}
                onChange={(e) => {
                    setNewName({ value: e.target.value, state: 'default' });
                }}
                placeholder="이름"
                className={`${newName?.state === 'error' ? 'inputErrorStyle' : 'inputDefaultStyle'} inputCommonStyle `}
            />
            <input
                type="tel"
                value={parentPhone.value}
                onChange={(e) => {
                    const tel = handleTelValue(e);
                    setParentPhone({ value: tel, state: 'default' });
                }}
                placeholder="보호자 연락처"
                className={`${
                    parentPhone?.state === 'error' ? 'inputErrorStyle' : 'inputDefaultStyle'
                } inputCommonStyle `}
            />

            <SearchMap
                modalBtn={
                    <div
                        className={`p-[12px] w-full h-[44px] text-context-regular rounded-[8px] bg-secondary-mGrayLight relative ${
                            address?.road_address_name ? 'text-primary-black' : 'text-secondary-lGrayDark'
                        }`}
                    >
                        {address?.road_address_name ? address?.road_address_name : '주소'}
                        <SearchIcon className="absolute right-[12px] top-[12px] text-secondary-dGrayDark" />
                    </div>
                }
                setSelectAddress={handleAddress}
            />

            <DatePicker
                modalBtn={
                    <div className="flex justify-between inputDefaultStyle inputCommonStyle">
                        {birth ? birth : '생년월일'}
                        <CalendarIcon />
                    </div>
                }
                selected={birth}
                setSelected={setBirth}
            />

            <div className="relative">
                <input
                    type="text"
                    value={height}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                            // 정규식을 사용해 숫자인지 체크
                            setHeight(Number(value));
                        }
                    }}
                    placeholder="키"
                    max={999.9}
                    className="inputDefaultStyle inputCommonStyle"
                />
                <div className="absolute right-[12px] top-[12px] text-context-regular text-primary-black">cm</div>
            </div>
            <div className="relative">
                <input
                    type="text"
                    value={weight}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                            // 정규식을 사용해 숫자인지 체크
                            setWeight(Number(value));
                        }
                    }}
                    placeholder="몸무게"
                    max={999.9}
                    className="inputDefaultStyle inputCommonStyle"
                />{' '}
                <div className="absolute right-[12px] top-[12px] text-context-regular text-primary-black">kg</div>
            </div>

            <input
                type="text"
                value={position}
                placeholder="포지션"
                className="inputDefaultStyle inputCommonStyle"
            />
            <div className="h-[40px] ">
                <div className="flex justify-between">
                    <div className=" text-secondary-lGrayDark text-context-bold py-[12px]">주발</div>
                    <div className="flex">
                        <button
                            onClick={() => handleMajorFoot('left')}
                            className={`flex items-center text-subtitle-large-regular ${
                                selectedFoots.left ? 'text-primary-purple' : 'text-secondary-lGrayMid'
                            }`}
                        >
                            <LFootIcon
                                className={selectedFoots.left ? 'text-primary-purple' : 'text-secondary-lGrayMid'}
                            />
                            <div>L</div>
                        </button>
                        <div className="w-[10px]"></div>
                        <button
                            onClick={() => handleMajorFoot('right')}
                            className={`flex items-center text-subtitle-large-regular ${
                                selectedFoots.right ? 'text-primary-purple' : 'text-secondary-lGrayMid'
                            }`}
                        >
                            <div>R</div>
                            <RFootIcon
                                className={selectedFoots.right ? 'text-primary-purple' : 'text-secondary-lGrayMid'}
                            />
                        </button>
                    </div>
                </div>
            </div>
            <input
                type="text"
                value={uniformNumber}
                onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                        // 정규식을 사용해 숫자인지 체크
                        setUniformNumber(Number(value));
                    }
                }}
                placeholder="등번호"
                className="inputDefaultStyle inputCommonStyle"
            />

            <button
                onClick={submitProfile}
                className="p-[12px] h-[44px] text-context-bold text-primary-white rounded-[8px] bg-primary-purple"
            >
                작성완료
            </button>
        </div>
    );
};

export default PlayerInfoForm;
