// hooks
import React from 'react';
import { useRoutes } from 'react-router-dom';
// pages
import ProtectedRoute from 'routes/ProtectedRoute';
// import CoachPage from './pages/staff/CoachPage';
// import SystemAdminPage from './pages/admin/SystemAdminPage';

// player page
import MeasurementMain from 'pages/player/measurement/main';
import Join from 'pages/player/join';
import Analysis from 'pages/player/measurement/analysis';
import PlayerMypage from 'pages/player/mypage';

// auth
import Unauthorized from 'pages/auth/unauthorized';
import SignIn from 'pages/auth/signIn';
import Email from 'pages/auth/email';
import Success from 'pages/auth/OAuth/Success';
import Failure from 'pages/auth/OAuth/Failure';
// initial
import InitalPage from 'pages/initialPage';
// staff
// staff-lineup
import LineUp from 'pages/staff/control/lineUp';
import LineUpId from 'pages/staff/control/lineUp/[id]';
import Injury from 'pages/staff/control/lineUp/[id]/injury';
import Career from 'pages/staff/control/lineUp/[id]/career';
import Season from 'pages/staff/control/lineUp/[id]/season';
// staff-rating
import Rating from 'pages/staff/control/rating';
import RatingId from 'pages/staff/control/rating/[id]';
import StaffMypage from 'pages/staff/mypage';
// staff-schedule
import Schedule from 'pages/staff/control/schedule';
// admin
import UserControl from 'pages/admin/userControl';
// role: PLAYER, TEAM_STAFF, SYSTEM_ADMIN, DATA_ANALYST, SUPER_ADMIN
// staffRole: COACH, MANAGER, OFFICE

export const routesConfig = [
    {
        path: '/',
        element: <SignIn />,
    },
    {
        path: '/signIn',
        element: <SignIn />,
    },
    {
        path: '/signIn/email',
        element: <Email />,
    },
    { path: '/login/oauth', element: <Success /> },
    { path: '/login/authentication-failure', element: <Failure /> },
    { path: '/initial', element: <InitalPage /> },
    {
        path: '/player',
        element: <ProtectedRoute allowedRoles={['PLAYER']} />,
        children: [
            { path: 'join', element: <Join /> }, // 기본 경로
            { path: 'measurement', element: <MeasurementMain /> }, // 기본 경로
            { path: 'measurement/:id', element: <MeasurementMain /> }, // 동적 경로
            { path: 'measurement/analysis/:id', element: <Analysis /> }, // 분석 동적 경로
            { path: 'mypage', element: <PlayerMypage /> }, // 분석 동적 경로
        ],
    },
    {
        path: '/staff',
        element: <ProtectedRoute allowedRoles={['TEAM_STAFF']} />,
        children: [
            { path: 'mypage', element: <StaffMypage /> },
            {
                path: 'control',
                element: <ProtectedRoute allowedStaffRoles={['COACH', 'MANAGER', 'OFFICE']} />,
                children: [
                    { path: 'lineUp', element: <LineUp /> },
                    { path: 'lineUp/:id', element: <LineUpId /> },
                    { path: 'lineUp/:id/season', element: <Season /> },
                    { path: 'lineUp/:id/injury', element: <Injury /> },
                    { path: 'lineUp/:id/career', element: <Career /> },

                    { path: 'rating', element: <Rating /> },
                    { path: 'rating/:id', element: <RatingId /> },
                    { path: 'schedule', element: <Schedule /> },
                    { path: 'medical', element: <LineUp /> },
                ],
            },
        ],
    },
    {
        path: '/admin',
        element: <ProtectedRoute allowedRoles={['SUPER_ADMIN', 'SYSTEM_ADMIN']} />,
        children: [
            { path: 'userControl', element: <UserControl /> },
            {
                path: 'system',
                element: <ProtectedRoute allowedStaffRoles={['SYSTEM_ADMIN']} />,
                children: [
                    // { path: 'details/:id', element: <CoachDetailPage /> },
                ],
            },
        ],
    },
    {
        path: '*',
        element: <Unauthorized />,
    },
];

const AppRoutes = () => {
    const routes = useRoutes(routesConfig);
    return routes;
};
export default AppRoutes;
