// hooks
import { useEffect, useState } from "react";
// kakao
import { Map, MapMarker } from "react-kakao-maps-sdk";

// common
interface SearchMapType {
  modalBtn?: React.ReactNode;
  setSelectAddress?: (address: any) => void;
}
export interface MarkerType {
  place_name: string;
  road_address_name: string;
}
const SearchMap = ({ modalBtn, setSelectAddress }: SearchMapType) => {
  const { kakao } = window;
  const [isShow, setIsShow] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [serchKeyword, setSerchKeyword] = useState("");
  const [info, setInfo] = useState();
  const [markers, setMarkers] = useState<MarkerType[]>([]);
  const [selectMarker, setSelectMarker] = useState<MarkerType>();

  const searchFunc = () => {
    setSerchKeyword(keyword);
  };

  const saveAddress = () => {
    if (setSelectAddress) setSelectAddress(selectMarker);
    setIsShow(false);
  };
  useEffect(() => {
    if (!kakao) {
      return;
    }

    const ps = new kakao.maps.services.Places();

    ps.keywordSearch(serchKeyword, (data, status, _pagination) => {
      if (serchKeyword && status === kakao.maps.services.Status.OK) {
        // 검색된 장소 위치를 기준으로 지도 범위를 재설정하기위해
        // LatLngBounds 객체에 좌표를 추가합니다
        const bounds = new kakao.maps.LatLngBounds();
        let markers = [];

        for (var i = 0; i < data.length; i++) {
          // @ts-ignore
          // console.log(data[i]);
          markers.push({
            position: {
              lat: data[i]?.y,
              lng: data[i]?.x,
            },
            place_name: data[i].place_name,
            address_name: data[i].address_name,
            road_address_name: data[i].road_address_name,
          });
          // @ts-ignore
          bounds.extend(new kakao.maps.LatLng(data[i].y, data[i].x));
        }
        setMarkers(markers);

        // 검색된 장소 위치를 기준으로 지도 범위를 재설정합니다
        // map.setBounds(bounds);
      }
    });
  }, [serchKeyword]);

  return (
    <div>
      <div onClick={() => setIsShow(!isShow)}>{modalBtn}</div>
      {isShow && (
        <div className="fixed top-0 left-0 z-10 flex items-center justify-center w-screen h-screen bg-[#00000050]">
          <div className="bg-primary-white w-[400px] h-[400px] rounded-[10px]">
            <div className="flex justify-between p-[20px] items-center text-[16px] font-bold">
              <div>주소검색</div>
              <div className="w-50 h-50" onClick={() => setIsShow(false)}>
                x
              </div>
            </div>

            <div className="px-[10px] flex gap-[5px]">
              <input
                type="text"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="주소를 검색해주세요"
                className="inputDefaultStyle inputCommonStyle"
              />
              <button
                type="button"
                onClick={() => searchFunc()}
                className="w-[65px] text-[16px] h-[40px] bg-primary-purple text-primary-white font-bold rounded-[8px]"
              >
                검색
              </button>
            </div>
            <div
              className={`mt-[22px] ${
                markers.length > 0 && "h-[60%] overflow-y-scroll pb-[10px]"
              }`}
            >
              {markers.map((marker, idx) => (
                <div
                  onClick={() => setSelectMarker(marker)}
                  key={idx}
                  className={
                    marker === selectMarker
                      ? "bg-primary-purple px-[15px] py-[5px] text-white flex justify-between items-end"
                      : idx % 2 === 0
                      ? "bg-[#F7F9FB] px-[15px] py-[5px]"
                      : "px-[15px] py-[5px]"
                  }
                >
                  <div>
                    <div className="text-[16px] font-bold">
                      {marker.place_name}
                    </div>
                    <div className="text-[14px] mt-1">
                      {marker.road_address_name}
                    </div>
                  </div>
                  {marker === selectMarker && (
                    <button
                      className="bg-primary-white font-bold mb-[5px] text-primary-purple text-[14px] px-[6px] h-fit py-[4px] rounded-[4px]"
                      onClick={() => saveAddress()}
                    >
                      선택
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default SearchMap;
