export const dropDownFormChanger = (
  valueArr: string[],
  labelArr?: string[]
) => {
  //  [{ value: 'value1', label: 'label1' },...
  if (labelArr) {
    return valueArr.map((value, index) => ({
      value: value,
      label: labelArr[index] || value, // labelArr의 값이 없으면 value로 대체
    }));
  } else if (!labelArr) {
    return valueArr.map((value) => ({
      value: value,
      label: value,
    }));
  } else return [];
};
export interface dropDownFormChangerType {
  value: string;
  label: string;
}
