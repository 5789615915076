// assets
import graySymbol from 'assets/logos/symbol/graySymbol.png';
// icons
import { ReactComponent as LFootIcon } from 'assets/svgs/lFoot.svg';
import { ReactComponent as RFootIcon } from 'assets/svgs/rFoot.svg';
// common components
import Stars from 'components/stars/Default';
const ProfileCard = () => {
    return (
        <div className="w-[927px] h-[360px] bg-primary-white rounded-[10px] p-[30px] flex gap-[30px]">
            <div className="flex justify-center items-center rounded-[8px] w-[230px] h-[280px] bg-secondary-mGrayLight">
                <img
                    src={graySymbol}
                    alt="graySymbol"
                    className="w-[80px]"
                />
            </div>
            <div>
                <div className="flex gap-[10px] items-center">
                    <div className="text-title-small-bold">제리예이츠</div>
                    <span className="w-[79px] h-[29px] bg-[#5E6EFF] rounded-[80px] text-[14px] font-bold flex justify-center items-center text-primary-white">
                        등번호 19
                    </span>
                </div>
                <div className="font-semiBold text-[20px] text-[#868686]">스트라이커(중앙) AM(L)갤로핑FC</div>
                <div className="mt-[20px] grid grid-cols-2 w-[607px]">
                    <div className="h-[164px] flex items-start w-full">
                        <div className="flex items-center">
                            <div className="h-[42px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                            <div className="text-[20px] font-semibold mr-[12px] text-[#868686] ">등급</div>
                        </div>
                        <div className="flex flex-col justify-center items-center w-[126px] h-[164px] p-[30px] gap-[10px] ">
                            <div className="h-[100px] flex justify-center items-center text-[100px] font-bold ">S</div>
                            {/* <Stars count={4.5} />
                            <div className="text-[#7c7c7c] text-[22px]">
                                <span className="text-primary-purple text-subtitle-large-bold">4.5</span> / 5
                            </div> */}
                        </div>
                    </div>
                    <div className="w-full flex flex-col items-start justify-center gap-[20px] ">
                        <div className="h-[48px] flex items-center">
                            <div className="h-[42px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                            <div className="text-[20px] font-semibold mr-[12px] text-[#868686]">신장</div>
                            <div className="text-[50px] mr-[10px] font-bold">175</div>
                            <div className="text-[22px] text-[#7c7c7c]">Cm</div>
                        </div>
                        <div className="h-[48px] flex items-center">
                            <div className="h-[42px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                            <div className="text-[20px] font-semibold mr-[12px] text-[#868686]">체중</div>
                            <div className="text-[50px] mr-[10px] font-bold">70</div>
                            <div className="text-[22px] text-[#7c7c7c]">Kg</div>
                        </div>
                        <div className="h-[48px] flex items-center">
                            <div className="h-[42px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                            <div className="text-[20px] font-semibold mr-[12px] text-[#868686]">주발</div>
                            <div className="flex items-center text-subtitle-large-regular text-secondary-lGrayMid">
                                <LFootIcon />
                                <div>L</div>
                            </div>
                            <div className="w-[10px]"></div>
                            <div className="flex items-center text-subtitle-large-bold text-primary-purple">
                                <div>R</div>

                                <RFootIcon />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;
