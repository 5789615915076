import React, { useState } from 'react';

interface TabProps {
    label: string;
    // content: React.ReactNode;
}

interface TabsProps {
    tabs: TabProps[];
    tabClassName?: string;
    defaultActiveTab?: number;
    onTabChange?: (index: number) => void;
    className?: string;
}

const Tabs: React.FC<TabsProps> = ({ tabs, tabClassName, defaultActiveTab = 0, onTabChange, className = '' }) => {
    const [activeTab, setActiveTab] = useState(defaultActiveTab);

    const handleTabChange = (index: number) => {
        setActiveTab(index);
        onTabChange?.(index);
    };

    return (
        <div>
            <div className={`flex ${className} bg-secondary-mGrayLight rounded-[10px]`}>
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        onClick={() => handleTabChange(index)}
                        className={` px-[20px] py-[10px] rounded-[10px] ${tabClassName}
              ${
                  activeTab === index
                      ? 'bg-primary-black text-primary-white text-context-bold'
                      : 'bg-secondary-mGrayLight text-[#8B8B8B] text-context-regular'
              }
              transition-all duration-200
              hover:opacity-80
            `}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            {/* <div className="mt-4">{tabs[activeTab].content}</div> */}
        </div>
    );
};

export default Tabs;
// 사용방법
// const tabData = [
//     {
//         label: '첫 번째 탭',
//         content: <div>첫 번째 탭의 내용</div>,
//     },
//     {
//         label: '두 번째 탭',
//         content: <div>두 번째 탭의 내용</div>,
//     },
//     {
//         label: '세 번째 탭',
//         content: <div>세 번째 탭의 내용</div>,
//     },
// ];
// <Tabs
// tabs={tabData}
// defaultActiveTab={0}
// onTabChange={(index) => console.log(`Selected tab: ${index}`)}
// />
