import { useEffect, useState } from 'react';

//icons
import { ReactComponent as MenuIcon } from 'assets/svgs/menu.svg';
// common
import Dropdown from 'components/dropdown/Default';
import MoreNavBtn from 'components/button/MoreNavBtn';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';

const RatingIdSubHeader = () => {
    const handleParamNavigate = useParamNavigate();
    const options = [
        { value: 'U12', label: 'U12' },
        { value: 'U15', label: 'U15' },
        { value: 'U18', label: 'U18' },
    ];
    const players = [
        { value: '19', label: '19. 제리예이츠' },
        { value: '20', label: '20. 손흥민' },
        { value: '21', label: '21. 김민재' },
    ];
    const branchs = [
        { value: '2024_1', label: '2024년 1차' },
        { value: '2024_2', label: '2024년 2차' },
        { value: '2024_3', label: '2024년 3차' },
    ];
    const [youthRange, setYouthRange] = useState('U12');
    const [player, setPlayer] = useState('19');
    const [branch, setBranch] = useState('2024_1');
    useEffect(() => {
        if (youthRange)
            handleParamNavigate({
                ageGroup: youthRange,
                player: player,
                branch: branch,
            });
    }, [youthRange, player, branch]);

    return (
        <div className="h-[80px] ">
            <div className="flex items-center justify-between">
                <div className="flex gap-[14px] items-center">
                    <MenuIcon className="text-[#BABABD] w-[30px] h-[30px] mr-[6px]" />
                    <div className="text-primary-white text-title-small-bold">
                        <div>갤로핑 FC 선수단</div>
                    </div>
                    <div className="gap-[7px] flex w-[420px]">
                        <Dropdown
                            options={options}
                            value={youthRange}
                            defaultValue={'U12'}
                            onChange={setYouthRange}
                            className={'w-[82px] border rounded-[8px] border-primary-white'}
                        />
                        <Dropdown
                            options={players}
                            value={player}
                            defaultValue={'19'}
                            onChange={setPlayer}
                            className={'border rounded-[8px] border-primary-white'}
                        />
                        <Dropdown
                            options={branchs}
                            value={branch}
                            defaultValue={'2024년 1차'}
                            onChange={setBranch}
                            className={'border rounded-[8px] border-primary-white'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RatingIdSubHeader;
