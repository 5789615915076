export const skills = [
  { value: "드리블", label: "드리블" },
  { value: "패스", label: "패스" },
  { value: "슈팅", label: "슈팅" },
  { value: "퍼스트터치", label: "퍼스트터치" },
];

export const skillTab = [
  {
    label: "드리블",
  },
  {
    label: "패스",
  },
  {
    label: "슈팅",
  },
  {
    label: "퍼스트터치",
  },
];
