// commons
import CustomTable from "components/table/CustomTable";
import LineUpIdSubNavHeader from "pages/staff/control/lineUp/[id]/LineUpIdSubNavHeader";
import EmptyBar from "components/emptyBar";
// utils
import { seasonData } from "utils/tmp/staffTmpData";
const Career = () => {
  const columns = [
    { key: "year", label: "년도", sortable: true },
    { key: "grade", label: "학년", sortable: true },
    { key: "team", label: "팀", sortable: true },
    { key: "participation", label: "총 출장", sortable: true },
    { key: "goal", label: "골", sortable: true },
    { key: "assist", label: "도움", sortable: true },
    { key: "average", label: "평점", sortable: true },
  ];
  const rows = [
    {
      year: "2024.12.30",
      grade: "중2",
      team: "갤로핑FC",
      participation: 10,
      goal: 8,
      assist: 5,
      average: 8.5,
    },
    {
      year: "2024.12.30",
      grade: "중2",
      team: "갤로핑FC",
      participation: 10,
      goal: 8,
      assist: 5,
      average: 8.5,
    },
    {
      year: "2024.12.30",
      grade: "중2",
      team: "갤로핑FC",
      participation: 10,
      goal: 8,
      assist: 5,
      average: 8.5,
    },
    {
      year: "2024.12.30",
      grade: "중2",
      team: "갤로핑FC",
      participation: 10,
      goal: 8,
      assist: 5,
      average: 8.5,
    },
    {
      year: "2024.12.30",
      grade: "중2",
      team: "갤로핑FC",
      participation: 10,
      goal: 8,
      assist: 5,
      average: 8.5,
    },
    {
      year: "2024.12.30",
      grade: "중2",
      team: "갤로핑FC",
      participation: 10,
      goal: 8,
      assist: 5,
      average: 8.5,
    },
    {
      year: "2024.12.30",
      grade: "중2",
      team: "갤로핑FC",
      participation: 10,
      goal: 8,
      assist: 5,
      average: 8.5,
    },
    {
      year: "2024.12.30",
      grade: "중2",
      team: "갤로핑FC",
      participation: 10,
      goal: 8,
      assist: 5,
      average: 8.5,
    },
    {
      year: "2024.12.30",
      grade: "중2",
      team: "갤로핑FC",
      participation: 10,
      goal: 8,
      assist: 5,
      average: 8.5,
    },
    {
      year: "2024.12.30",
      grade: "중2",
      team: "갤로핑FC",
      participation: 10,
      goal: 8,
      assist: 5,
      average: 8.5,
    },
    {
      year: "2024.12.30",
      grade: "중2",
      team: "갤로핑FC",
      participation: 10,
      goal: 8,
      assist: 5,
      average: 8.5,
    },
    {
      year: "2024.12.30",
      grade: "중2",
      team: "갤로핑FC",
      participation: 10,
      goal: 8,
      assist: 5,
      average: 8.5,
    },
    {
      year: "2024.12.30",
      grade: "중2",
      team: "갤로핑FC",
      participation: 10,
      goal: 8,
      assist: 5,
      average: 8.5,
    },
    {
      year: "2024.12.30",
      grade: "중2",
      team: "갤로핑FC",
      participation: 10,
      goal: 8,
      assist: 5,
      average: 8.5,
    },
    {
      year: "2024.12.30",
      grade: "중2",
      team: "갤로핑FC",
      participation: 10,
      goal: 8,
      assist: 5,
      average: 8.5,
    },
  ];
  return (
    <div className="w-[1400px] m-auto">
      <LineUpIdSubNavHeader />

      {/* 경력정보 */}
      <div className="w-full bg-white rounded-[10px] p-[30px] flex flex-col gap-[20px] ">
        <div className="flex items-cent first-line:er gap-[10px]">
          <div className="text-subtitle-medium-bold text-secondary-dGrayMid">
            경력정보
          </div>
        </div>
        <div className="w-full">
          {/* overflow-y-scroll */}
          <CustomTable
            columns={columns}
            rows={rows}
            equalWidth={true}
            minWidth={"min-w-[116px]"}
          />
        </div>
      </div>
      <EmptyBar customStyle={"h-[20px]"} />
    </div>
  );
};

export default Career;
