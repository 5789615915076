// hooks
import { useLocation, useNavigate } from 'react-router-dom';
// images
import AisoccerFont from 'assets/logos/font-logo/aisoccer-white-font.webp';

const Failure = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const errorMessage = searchParams.get('error');

    return (
        <div className="fixed inset-0 z-10 flex items-center justify-center ">
            <div className="w-fit m-2 p-5 z-[11] bg-white text-base text-secondary-dGrayDark lg:text-lg text-y-gold rounded-lg">
                <div className="mb-5 font-bold text-center">[ ERROR ]</div>
                <img
                    src={AisoccerFont}
                    alt="PitchInside"
                    className="m-auto w-14"
                />
                <div className="mt-5 text-center">
                    <div>{errorMessage === 'Duplicate Email Provider' ? '중복된 이메일 입니다' : ''}</div>
                    <div>올바른 계정으로 로그인 바랍니다.</div>
                </div>

                <div className="flex justify-end mt-4">
                    <button
                        onClick={() => navigate('/')}
                        className="px-3 py-2 border rounded-md bg-main-purple text-text-white"
                    >
                        확인
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Failure;
