// hooks
import { useState } from 'react';
// api
import { useApiMutation, useLoginMutation } from 'hooks/useDynamicApi';
// commons
import { regionOptions, positionOptions } from 'utils/consts/userInfo';
import { showToast } from 'components/toast/Default';
// utils
import { ENDPOINT } from 'utils/consts/apiEndpoint';
import Dropdown from 'components/dropdown/Default';
const TeamControl = () => {
    const [name, setName] = useState('');
    const [ageGroup, setAgeGroup] = useState('U10');
    const [region, setRegion] = useState('');
    const [type, setType] = useState();
    const [teamStaffIds, setTeamStaffIds] = useState([]);

    // ReactQuery Mutation
    const postTeamMutation = useApiMutation(ENDPOINT.admin.super.team, 'post', {
        onSuccess: (data) => {
            showToast('팀이 생성되었습니다.', 'success');
        },
        onError: (error) => {
            showToast(`${error}`, 'error');
        },
    });
    // submit Func
    const addTeam = () => {
        if (name && ageGroup && region && type && teamStaffIds.length > 0) {
            postTeamMutation.mutate({
                name: name,
                ageGroup: ageGroup,
                type: type,
                region: region,
                teamStaffIds: [0],
            });
        }
    };
    return (
        <div className="flex flex-col gap-[14px] w-[1000px] m-auto">
            <Dropdown
                options={regionOptions}
                value={region}
                onChange={setRegion}
            />
            <div>
                <input placeholder="팀명을 검색해주세여" />
                <button>검색</button>
            </div>
            <button className="border border-black">팀생성</button>
        </div>
    );
};

export default TeamControl;
