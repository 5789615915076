// hooks
import { useState, useEffect } from 'react';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// commons
import EmptyBar from 'components/emptyBar';
import PasswordChange from 'components/modals/PasswordChange';
// assets
import graySymbol from 'assets/logos/symbol/graySymbol.png';
// staff components
import MypageSubHeader from 'pages/staff/mypage/MySubHeader';
// icons
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';
// redux
import { useSelector } from 'react-redux';
import { RootState } from 'store'; // Redux Store 타입 정의

interface MyInfoField {
    value: string | string[] | null; // teamNames가 배열일 수 있으므로
    state: 'disabled' | 'default' | 'error';
}

interface MyInfo {
    [key: string]: MyInfoField;
}

const Mypage = () => {
    const {
        name,
        id,
        email,
        profileImageUrl,
        teamNames,
        role, // null 허용
        staffRole, // 이미 null 허용됨
        status,
    } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    // api
    // const { data, isLoading, isSuccess, isError, error, refetch } = useApiQuery(['getUser'], '/api/user', 'get');
    // useEffect(() => {
    //     if (isSuccess && data) {
    //         console.log(data);
    //     }
    // }, []);

    const [isEdit, setIsEdit] = useState(false);

    const playerInputs = [
        { label: '이름', value: 'name' },
        { label: '이메일', value: 'email' },
        { label: '주소', value: 'address' },
        { label: '성별', value: 'gener' },
        { label: '신장', value: 'height' },
        { label: '몸무게', value: 'weight' },
        { label: '등번호', value: 'uniformNumber' },
        { label: '포지션', value: 'position' },
        { label: '주 발', value: 'mainFoot' },
        { label: '지역', value: 'region' },
        { label: '소속팀', value: 'teamNames' },
        { label: '역할', value: 'role' },
    ];
    const staffInputs = [
        { label: '이름', value: 'name' },
        { label: '이메일', value: 'email' },
        { label: '역할', value: 'role' },
        { label: '상세역할', value: 'staffRole' },
    ];
    const [myInfo, setMyInfo] = useState<MyInfo>({
        name: { value: name, state: 'default' },
        email: { value: email, state: 'default' },
        address: { value: '경기도 수원시 팔달구 우만동', state: 'default' },
        gener: { value: '남성', state: 'default' },
        height: { value: '178', state: 'default' },
        weight: { value: '60', state: 'default' },
        uniformNumber: { value: '11', state: 'default' },
        position: { value: '미드필더', state: 'default' },
        mainFoot: { value: '왼 발', state: 'default' },
        region: { value: '경기', state: 'default' },

        teamNames: { value: teamNames, state: 'disabled' },
        role: { value: role, state: 'disabled' },
    });
    const handleInputChange = (field: string, value: string) => {
        setMyInfo((prev) => ({
            ...prev,
            [field]: {
                ...prev[field],
                value: value,
            },
        }));
    };
    return (
        <div className="w-[1400px] m-auto">
            <EmptyBar customStyle={'h-[35px]'} />
            <MypageSubHeader />
            <EmptyBar customStyle={'h-[35px]'} />
            <div className="bg-primary-white rounded-[10px] p-[30px] flex gap-[30px]">
                <div className="flex justify-center items-center rounded-[8px] w-[230px] h-[280px] bg-secondary-mGrayLight">
                    <img
                        src={graySymbol}
                        alt="graySymbol"
                        className="w-[80px]"
                    />
                </div>
                <div className="flex-col flex gap-[24px] w-[1080px]">
                    <div className="h-[64px] mb-[24px] flex justify-between items-end">
                        <div>
                            <div className="text-title-small-bold">{name}</div>
                            <div className="text-[#868686] text-[20px] font-semibold flex  items-center">
                                <div className="flex gap-[10px]">
                                    <span>갤로핑FC</span>
                                    <span>감독</span>
                                    <span>{role}</span>
                                    <button
                                        onClick={() => setIsEdit(!isEdit)}
                                        className={`ml-[4px] ${
                                            isEdit ? 'text-primary-purple' : 'text-secondary-mGrayDark'
                                        }`}
                                    >
                                        <EditPenIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {isEdit && (
                            <div className="flex gap-[8px]">
                                <button
                                    onClick={() => setIsEdit(false)}
                                    className="w-[80px] h-[44px] bg-primary-purple p-[12px] rounded-[8px] text-primary-white text-context-bold"
                                >
                                    저장하기
                                </button>
                                <button
                                    onClick={() => setIsEdit(false)}
                                    className="w-[80px] h-[44px] bg-secondary-dGrayLight p-[12px] rounded-[8px] text-primary-white text-context-bold"
                                >
                                    취소하기
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="grid grid-cols-2 gap-[14px]">
                        {role === 'PLAYER'
                            ? playerInputs.map((el) => (
                                  <div className="flex gap-[14px] items-center">
                                      <div className="text-subtitle-small-bold w-[100px] text-center">{el.label}</div>
                                      <input
                                          readOnly={!isEdit || myInfo[el.value].state === 'disabled'}
                                          value={myInfo[el.value].value || ''} // null 체크
                                          onChange={(e) => handleInputChange(el.value, e.target.value)}
                                          className={`${
                                              !isEdit || myInfo[el.value].state === 'disabled'
                                                  ? 'inputDisabledStyle'
                                                  : 'inputDefaultStyle'
                                          } inputCommonStyle`}
                                      />
                                  </div>
                              ))
                            : staffInputs.map((el) => (
                                  <div className="flex gap-[14px] items-center">
                                      <div className="text-subtitle-small-bold w-[100px] text-center">{el.label}</div>
                                      <input
                                          readOnly={!isEdit || myInfo[el.value].state === 'disabled'}
                                          value={myInfo[el.value].value || ''} // null 체크
                                          onChange={(e) => handleInputChange(el.value, e.target.value)}
                                          className={`${
                                              !isEdit || myInfo[el.value].state === 'disabled'
                                                  ? 'inputDisabledStyle'
                                                  : 'inputDefaultStyle'
                                          } inputCommonStyle`}
                                      />
                                  </div>
                              ))}
                    </div>
                    {status === 'PENDING_PASSWORD' && (
                        <div className="flex justify-end w-full">
                            <PasswordChange
                                modalBtn={
                                    <button className="p-[12px] rounded-[8px] bg-primary-purple text-primary-white font-bold">
                                        비밀번호 변경
                                    </button>
                                }
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default Mypage;
