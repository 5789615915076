import { verify } from 'crypto';

export const ENDPOINT = {
    player: {
        profile: '/player/profile',
        whoAmI: '/player/profile',
    },
    coach: {
        team: '/coach/team',
        addPlayer: '/coach/player',
    },
    staff: {
        team: {
            ageGroups: '/api/team/age-groups',
        },
        supervision: {
            reports: '/staff/supervision/reports',
            approveReport: (id: string) => `/staff/supervision/reports/${id}/approve`,
        },
        office: {
            documents: '/staff/office/documents',
        },
    },
    admin: {
        super: { team: '/api/team' },
        role: (id: string) => `/api/user/${id}/role`, // 어드민이 권한 요청 변경하는 것
    },
    user: {
        user: '/api/user',
        register: '/api/user/register',
        self: '/api/user/role/self', //자신의 권한 변경 요청
        profile: '/api/user/player/profile', // 선수 프로필 등록
        password_initial: '/api/user/password/initial',
    },
    authentication: {
        login: '/api/login',
    },
    email: {
        send: '/api/email/send',
        verify: '/api/email/verify',
    },
};

export const ENDPOINT_MANAGEMENT_PROFILE = {
    player: (playerId: string) => `/api/management/player/profile/${playerId}`, // 어드민이 권한 요청 변경하는 것
    injury: {
        current: (playerId: string) => `/api/management/injury/current/${playerId}`,
        history: (playerId: string) => `/api/management/injury/history/player/${playerId}`,
    },
    season: {
        team: (seasonYear: string, params?: { [key: string]: string | number | undefined }) => {
            const baseUrl = `/api/management/team/profile/season/${seasonYear}`;
            if (!params) return baseUrl;
            const queryString = new URLSearchParams(
                Object.entries(params)
                    .filter(([_, value]) => value !== undefined)
                    .map(([key, value]) => [key, String(value)])
            ).toString();

            return `${baseUrl}?${queryString}`;
        },
        players: (seasonYear: string, params?: { [key: string]: string | number | undefined }) => {
            const baseUrl = `/api/management/team/profile/season/${seasonYear}/players`;
            if (!params) return baseUrl;
            const queryString = new URLSearchParams(
                Object.entries(params)
                    .filter(([_, value]) => value !== undefined)
                    .map(([key, value]) => [key, String(value)])
            ).toString();

            return `${baseUrl}?${queryString}`;
        },
    },
};

export const EXTERNAL_ENDPOINT = {
    googleForm:
        'https://docs.google.com/forms/u/0/d/e/1FAIpQLSdJciNY7U1MtvHqZ9mQ1XnYhh_mOvcGICIJlOXqAiRQ3jP1Bw/formResponse', // 폼의 제출 URL
};
