// hooks
import { useState } from 'react';
import axios from 'axios';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
// redux
import { useSelector } from 'react-redux';
import { RootState } from 'store';
// common
import EmptyBar from 'components/emptyBar';
import { showToast } from 'components/toast/Default';
// utils
import { ENDPOINT, EXTERNAL_ENDPOINT } from 'utils/consts/apiEndpoint';
import { FormDataKey } from 'utils/consts/googleFormKey';
const PermissionForm = () => {
    // PLAYER, TEAM_STAFF, SYSTEM_ADMIN, DATA_ANALYST, SUPER_ADMIN
    // COACH, MANAGER, OFFICE
    const { id, name } = useSelector((state: RootState) => state.userInfo);

    const [role, setRole] = useState('');
    const [subRole, setSubRole] = useState('');

    // ReactQuery Mutation
    const patchRoleRequest = useApiMutation(ENDPOINT.user.self, 'patch', {
        onSuccess: (data) => {
            showToast(`권한 변경 요청이 접수되었습니다, 잠시만 기다려주세요.`, 'success');

            if (id && role && subRole) {
                submitFormData(id, role, subRole);
            }
            console.log(data);
        },
        onError: (error) => {
            // 에러 메시지 추출
            showToast(`권한 변경 요청 실패: ${error}.`, 'error');
        },
    });
    // form handle Func

    const handleRoleChange = (selectedRole: string) => {
        setRole(selectedRole);
        if (selectedRole !== '스태프') {
            setSubRole(''); // 스태프가 아닌 경우 subRole 초기화
        }
    };
    // submit Func
    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (!role) {
            showToast(`역할을 선택해주세요.`, 'error');
            return;
        } else if (role === '스태프' && !subRole) {
            showToast(`스태프의 역할을 선택해주세요.`, 'error');
            return;
        } else if (!id) showToast(`id가 없습니다. 유효하지 않은 접근입니다.`, 'error');
        else if (id && role) {
            patchRoleRequest.mutate({
                role: role,
                staffRole: subRole ? subRole : null,
            });
        }
    };
    const submitFormData = async (id: number, role: string, subRole: string) => {
        const formData = new URLSearchParams();
        formData.append(FormDataKey.idKey, `${id} (이름:${name})`); // 폼 항목의 entry ID와 값을 맞춰야 합니다.
        formData.append(FormDataKey.roleKey, role); // 폼 항목의 entry ID와 값을 맞춰야 합니다.
        formData.append(FormDataKey.subRoleKey, subRole); // 서브롤을 선택한 경우

        try {
            const response = await axios.post(
                EXTERNAL_ENDPOINT.googleForm, // 폼의 제출 URL
                formData,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );
            console.log('폼 제출 성공:', response);
        } catch (error) {
            console.error('폼 제출 오류:', error);
        }
    };
    return (
        <div className="p-[10px] ">
            <div className="mb-[14px] text-label-regular text-secondary-lGrayDark">역할을 선택해 주세요</div>
            <form
                onSubmit={handleSubmit}
                className="flex flex-col gap-[18px] text-context-regular"
            >
                {/* Role: TEAM_STAFF */}
                <label
                    className="flex items-center gap-[10px]"
                    onClick={() => handleRoleChange('TEAM_STAFF')}
                >
                    <div
                        className={` relative w-[24px] h-[24px] rounded-full border flex items-center justify-center transition-all duration-200 ${
                            role === 'TEAM_STAFF'
                                ? 'border-2 border-primary-purple bg-primary-white'
                                : 'border-primary-black bg-primary-white'
                        } `}
                    >
                        {role === 'TEAM_STAFF' && (
                            <div className="absolute w-[12px] h-[12px] rounded-full bg-primary-purple" />
                        )}
                    </div>
                    스태프
                </label>

                {/* SubRole: TEAM_STAFF 전용 */}
                <div className="flex bg-secondary-mGrayLight gap-[14px] px-[33px] py-[15px] rounded-[8px]">
                    <label
                        className="h-[24px] flex items-center gap-[10px]"
                        onClick={() => setSubRole('COACH')}
                    >
                        <div
                            className={` relative w-[24px] h-[24px] rounded-full border flex items-center justify-center transition-all duration-200 ${
                                subRole === 'COACH'
                                    ? 'border-2 border-primary-purple bg-primary-white'
                                    : 'border-primary-black bg-primary-white'
                            } `}
                        >
                            {subRole === 'COACH' && (
                                <div className="absolute w-[12px] h-[12px] rounded-full bg-primary-purple" />
                            )}
                        </div>
                        코치
                    </label>

                    <label
                        className="h-[24px] flex items-center gap-[10px]"
                        onClick={() => setSubRole('MANAGER')}
                    >
                        <div
                            className={` relative w-[24px] h-[24px] rounded-full border flex items-center justify-center transition-all duration-200 ${
                                subRole === 'MANAGER'
                                    ? 'border-2 border-primary-purple bg-primary-white'
                                    : 'border-primary-black bg-primary-white'
                            } `}
                        >
                            {subRole === 'MANAGER' && (
                                <div className="absolute w-[12px] h-[12px] rounded-full bg-primary-purple" />
                            )}
                        </div>
                        감독
                    </label>
                    <label
                        className="h-[24px] flex items-center gap-[10px]"
                        onClick={() => setSubRole('OFFICE')}
                    >
                        <div
                            className={` relative w-[24px] h-[24px] rounded-full border flex items-center justify-center transition-all duration-200 ${
                                subRole === 'OFFICE'
                                    ? 'border-2 border-primary-purple bg-primary-white'
                                    : 'border-primary-black bg-primary-white'
                            } `}
                        >
                            {subRole === 'OFFICE' && (
                                <div className="absolute w-[12px] h-[12px] rounded-full bg-primary-purple" />
                            )}
                        </div>
                        사무국
                    </label>
                </div>

                {/* 기타 Role */}

                <label
                    className="flex items-center gap-[10px]"
                    onClick={() => handleRoleChange('SYSTEM_ADMIN')}
                >
                    <div
                        className={` relative w-[24px] h-[24px] rounded-full border flex items-center justify-center transition-all duration-200 ${
                            role === 'SYSTEM_ADMIN'
                                ? 'border-2 border-primary-purple bg-primary-white'
                                : 'border-primary-black bg-primary-white'
                        } `}
                    >
                        {role === 'SYSTEM_ADMIN' && (
                            <div className="absolute w-[12px] h-[12px] rounded-full bg-primary-purple" />
                        )}
                    </div>
                    시스템 관리자
                </label>

                <label
                    className="flex items-center gap-[10px]"
                    onClick={() => handleRoleChange('DATA_ANALYST')}
                >
                    <div
                        className={` relative w-[24px] h-[24px] rounded-full border flex items-center justify-center transition-all duration-200 ${
                            role === 'DATA_ANALYST'
                                ? 'border-2 border-primary-purple bg-primary-white'
                                : 'border-primary-black bg-primary-white'
                        } `}
                    >
                        {role === 'DATA_ANALYST' && (
                            <div className="absolute w-[12px] h-[12px] rounded-full bg-primary-purple" />
                        )}
                    </div>
                    훈련 분석관
                </label>

                {/* <label
                    className="flex items-center gap-[10px]"
                    onClick={() => handleRoleChange('SUPER_ADMIN')}
                >
                    <div
                        className={` relative w-[24px] h-[24px] rounded-full border flex items-center justify-center transition-all duration-200 ${
                            role === 'SUPER_ADMIN'
                                ? 'border-2 border-primary-purple bg-primary-white'
                                : 'border-primary-black bg-primary-white'
                        } `}
                    >
                        {role === 'SUPER_ADMIN' && (
                            <div className="absolute w-[12px] h-[12px] rounded-full bg-primary-purple" />
                        )}
                    </div>
                    슈퍼 어드민
                </label> */}

                <EmptyBar customStyle="h-[14px]" />
                <button
                    type="submit"
                    className="w-[400px] h-[44px] bg-primary-purple text-primary-white rounded-[8px] text-context-bold"
                >
                    요청하기
                </button>
            </form>
        </div>
    );
};

export default PermissionForm;
