const Footer = () => {
    return (
        <div className=" text-secondary-lGrayDark h-[100px] w-full left-0">
            <div className="flex gap-[10px] mb-[10px]">
                <div className="text-[16px] font-bold">(주)갤로핑</div>
                <div>
                    <a
                        href="https://www.notion.so/1512884832fc800cb8f3f5b6ffad95e9"
                        target="_blank"
                    >
                        이용약관
                    </a>
                    |
                    <a
                        href="https://burnt-colt-b56.notion.site/1512884832fc80b3bb8ad6dcbfbfb8f0"
                        target="_blank"
                    >
                        개인정보약관
                    </a>
                </div>
            </div>
            <div>
                대표이사: 손이경 | 업종 : 63991 데이터베이스 및 온라인정보제공업 | 대표번호: 070-4944-0141 | 주소:
                경기도 성남시 수정구 대왕판교로 815 판교제2테크노밸리 765호 | 사업자 등록 번호: 385-86-02714
            </div>
            <div>© AISoccer all rights reservedreserved</div>
        </div>
    );
};

export default Footer;
