// hooks
import React from 'react';
// apexchart
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { options } from 'components/chart/ApexChartExample/options';
import { series } from 'components/chart/ApexChartExample/series';

type SeriesType = {
    name: string;
    data: any[];
};
type ChartType = 'line' | 'bar' | 'area' | 'pie' | 'donut' | 'radar' | 'scatter'; // 추가

type ApexChartExampleProps = {
    customType?: ChartType;
    customOptions?: any | ApexOptions;
    customSeries?: SeriesType[];
    height?: number;
};
const ApexChartExample: React.FC<ApexChartExampleProps> = ({
    customOptions,
    customSeries,
    customType = 'line',
    height = 350,
}) => {
    return (
        <div className="w-full">
            {/* type을 line, bar, area, pie 등으로 변경 가능. */}
            <ReactApexChart
                options={customOptions || options}
                series={customSeries || series}
                type={customType}
                height={height}
            />
        </div>
    );
};

export default ApexChartExample;
