import { useState } from 'react';

interface ConfirmModalType {
    isShow: boolean;
    setIsShow: (state: boolean) => void;
    title?: string;
    activeFunc?: () => void;
}
const ConfirmModal = ({ isShow = false, setIsShow, title = '삭제하시겠습니까?', activeFunc }: ConfirmModalType) => {
    const handleModal = () => {
        if (activeFunc) activeFunc();
        setIsShow(false);
    };
    return (
        <>
            {isShow && (
                <div
                    onClick={() => setIsShow(false)}
                    className="fixed left-0 top-0 flex justify-center items-center w-full h-full bg-[#00000050]"
                >
                    <div className=" bg-white rounded-[10px] flex flex-col justify-center items-center p-[30px] gap-[20px]">
                        <div className="text-secondary-dGrayDark font-bold text-[18px]">{title}</div>
                        <div className="flex text-[14px] justify-end font-bold w-full  gap-[4px]">
                            <button
                                className="px-[14px] py-[7px] bg-secondary-lGrayLight rounded-[8px]"
                                onClick={() => setIsShow(false)}
                            >
                                취소
                            </button>
                            <button
                                className="px-[14px] py-[7px] bg-primary-purple text-primary-white rounded-[8px]"
                                onClick={handleModal}
                            >
                                확인
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ConfirmModal;
