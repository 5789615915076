import React from 'react';
// icons
import { ReactComponent as CheckIcon } from 'assets/svgs/check.svg';

interface RadioProps {
    value: string;
    selectedValue?: string;
    onChange?: (value: string) => void;
    label?: string;
    disabled?: boolean;
    className?: string;
}

const SquareRadio = ({
    value,
    selectedValue,
    onChange = () => {},
    label,
    disabled = false,
    className = '',
}: RadioProps) => {
    const handleClick = () => {
        if (!disabled) {
            onChange(value);
        }
    };

    const isSelected = selectedValue === value;

    return (
        <div
            className={`inline-flex items-center gap-2 cursor-pointer ${
                disabled ? 'cursor-not-allowed' : ''
            } ${className}`}
            onClick={handleClick}
        >
            <div
                className={`
          w-[24px] h-[24px] flex items-center justify-center rounded-[6px]
          border transition-colors duration-200
          ${
              isSelected && !disabled
                  ? 'bg-primary-black border border-primary-black'
                  : disabled
                  ? 'border border-secondary-mGrayDark bg-secondary-mGrayLight'
                  : 'border border-primary-black bg-primary-white'
          }
        `}
            >
                {isSelected && !disabled && (
                    <span className="text-primary-white w-[14.4px] h-[10.8px] ">
                        <CheckIcon />
                    </span>
                )}
            </div>
            {label && (
                <span className={`select-none ${disabled ? 'text-secondary-mGrayDark' : 'text-primary-black'}`}>
                    {label}
                </span>
            )}
        </div>
    );
};

const RadioGroup = ({
    children,
    value,
    onChange,
    className = '',
}: {
    children: React.ReactNode;
    value: string;
    onChange: (value: string) => void;
    className?: string;
}) => {
    return (
        <div className={`flex items-center gap-4 ${className}`}>
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child as React.ReactElement<RadioProps>, {
                        selectedValue: value,
                        onChange,
                    });
                }
                return child;
            })}
        </div>
    );
};

export { SquareRadio, RadioGroup };

// 사용 방법
// const [selectedValue, setSelectedValue] = useState('');
// <RadioGroup
//   value={selectedValue}
//   onChange={setSelectedValue}
// >
//   <SquareRadio
//       value="option1"
//       label="Option 1"
//   />
//   <SquareRadio
//       value="option2"
//       label="Option 2"
//   />
//   <SquareRadio
//       value="option3"
//       label="Disabled Option"
//       disabled
//   />
// </RadioGroup>
