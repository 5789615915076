export const radarOptions = {
  chart: {
    type: "radar",
    toolbar: { show: false },
    zoom: { enabled: false },
  },
  title: {
    show: false, // 제목 숨김
  },
  plotOptions: {
    radar: {
      size: 83, // 사이즈 설정

      polygons: {
        strokeColors: "#D6D6D6",
        strokeWidth: 1,
        // fill:{}
        connectorColors: "transparent", // y축 선 제거
      },
    },
  },
  markers: {
    size: 0,
    radius: "1px", // 네모 모양을 위해 radius를 0으로 설정
  },
  dataLabels: { enabled: false },
  stroke: {
    width: 0,
    // colors: ['#B8AFFF60', '#D7D7D7'],
    // curve: 'smooth',
  },
  colors: ["#717171", "#B8AFFF"], // 각 시리즈의 기본 색상
  fill: {
    opacity: 0.6,
    type: ["gradient", "gradient"], // 첫 번째 시리즈는 일반 색상, 두 번째 시리즈는 그라데이션
    gradient: {
      shade: "dark",
      type: "vertical",
      // gradientToColors: ['#71717160'], // 끝 색상
      stops: [0, 100],
      colorStops: [
        [
          {
            offset: 1,
            color: "#D7D7D7",
            opacity: 0.6,
          },
          {
            offset: 83,
            color: "#717171",
            opacity: 0.6,
          },
        ],
        [
          {
            offset: 0,
            color: "#B8AFFF",
            opacity: 0.6,
          },
          {
            offset: 100,
            color: "#B8AFFF",
            opacity: 0.6,
          },
        ],
      ],
    },
  },
  xaxis: {
    categories: ["잔발", "시야", "무게 중심", "무게축", "뒤꿈치", "무릎거리"],
    labels: {
      style: {
        colors: "#787878", // 모든 레이블에 동일한 색상 적용
        fontSize: "14px",
        fontWeight: "600",
      },
    },
    // labels: {
    //     formatter: function (value: string) {
    //         const date = new Date(value);
    //         const month = String(date.getMonth() + 1).padStart(2, '0');
    //         const day = String(date.getDate()).padStart(2, '0');
    //         return `${month}.${day}`;
    //     },
    //     style: { colors: '#5E6EFF', fontSize: '10px' },
    // },
  },
  yaxis: {
    show: false, // y축을 아예 보이지 않게 설정
    min: 0,
    max: 100,
    tickAmount: 4,
    labels: {
      show: false, // 세로축 라벨 제거
    },
  },
  legend: {
    // show: true,
    // layout: 'vertical', // 세로 배치
    // position: 'bottom', // 오른쪽에 위치하도록 설정
    // horizontalAlign: 'right', // 세로로 정렬되도록 설정
    // itemMargin: {
    //     horizontal: 5,
    // },
    position: "left", // 오른쪽에 위치하도록 설정
    floating: true,
    layout: "vertical", // 세로 배치

    itemMargin: {
      vertical: 5, // 항목 간 간격 추가
    },
    offsetY: 280,
    markers: {
      fillColors: ["#5E6EFF", "#B1B1B1"],
    },
  },
  tooltip: {
    enabled: true,
    theme: "dark",
    style: { fontSize: "12px", borderRadius: "5px" },
    offsetX: -30,
    offsetY: -30,
  },
};

export const radarSeries = [
  { name: "지난훈련", data: [50, 75, 20, 75, 20, 75] },
  { name: "최근훈련", data: [75, 50, 25, 100, 25, 80] },
];
