const InjuryEditTable = () => {
  const injuryRows = [
    {
      id: 1,
      updateDate: "2024.12.30",
      injury: "십자인대 부상",
      treatment: "물리치료, 재활치료",
      injuryDescription: "부상이에요..",
      hospital: "2024.12.30",
      returnDate: "2025.01.30",
    },
    {
      id: 2,
      updateDate: "2024.12.30",
      injury: "십자인대 부상",
      treatment: "물리치료, 재활치료",
      injuryDescription: "부상이에요..",
      hospital: "2024.12.30",
      returnDate: "2025.01.30",
    },
    {
      id: 3,
      updateDate: "2024.12.30",
      injury: "십자인대 부상",
      treatment: "물리치료, 재활치료",
      injuryDescription: "부상이에요..",
      hospital: "2024.12.30",
      returnDate: "2025.01.30",
    },
  ];
  return (
    <div className="">
      <div className="grid grid-cols-5 bg-primary-black text-primary-white rounded-[8px] h-[50px] text-context-regular">
        <div className="m-auto">최근 수정 일시</div>
        <div className="m-auto">부상내용</div>
        <div className="m-auto">치료내용</div>
        <div className="m-auto">부상일</div>
        <div className="m-auto">복귀일</div>
      </div>
      <div className="text-context-regular">
        {injuryRows.map((el, idx) => (
          <div
            key={el.id}
            className={`${
              idx % 2 === 1 ? "bg-secondary-mGrayLight" : ""
            } grid grid-cols-5 text-secondary-dGrayMid h-[50px] `}
          >
            <textarea
              readOnly={true}
              className="bg-transparent focus:outline-none resize-none p-[14px]  text-center"
            >
              {el.updateDate}
            </textarea>
            <textarea className="bg-transparent focus:outline-none resize-none p-[14px] text-center">
              {el.injury}
            </textarea>
            <textarea className="bg-transparent focus:outline-none resize-none p-[14px] text-center">
              {el.treatment}
            </textarea>
            <textarea className="bg-transparent focus:outline-none resize-none p-[14px] text-center">
              {el.hospital}
            </textarea>
            <textarea className="bg-transparent focus:outline-none resize-none p-[14px] text-center">
              {el.returnDate}
            </textarea>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InjuryEditTable;
