import React, { useState, useRef, useEffect } from 'react';

interface ToggleProps {
    options: string[];
    defaultSelected?: string;
    onChange?: (selected: string) => void;
    disabled?: boolean;
    className?: string;
    optionsStyle?: string;
}

const Toggle: React.FC<ToggleProps> = ({
    options,
    defaultSelected,
    onChange,
    disabled = false,
    className = '',
    optionsStyle = '',
}) => {
    const [selectedOption, setSelectedOption] = useState(defaultSelected || options[0]);
    const [maxWidth, setMaxWidth] = useState(0);
    const optionRefs = useRef<(HTMLButtonElement | null)[]>([]);

    useEffect(() => {
        // Find the maximum width among all options
        const widths = optionRefs.current.map((ref) => ref?.offsetWidth || 0);
        const maxOptionWidth = Math.max(...widths);
        setMaxWidth(maxOptionWidth);
    }, [options]);

    const handleOptionClick = (option: string) => {
        if (!disabled) {
            setSelectedOption(option);
            onChange?.(option);
        }
    };

    return (
        <div
            className={`
                relative
                bg-secondary-mGrayLight
                w-fit
                rounded-[12px]
                inline-flex
                items-center
                justify-center
                overflow-hidden
                ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
                ${className}
            `}
        >
            {/* Animated Background */}
            <div
                className="
                    absolute
                    top-0
                    bottom-0
                    bg-primary-black
                    transition-all
                    duration-300
                    ease-in-out
                    z-0
                    rounded-[12px]
                "
                style={{
                    left: `${(options.indexOf(selectedOption) / options.length) * 100}%`,
                    width: `${(1 / options.length) * 100}%`,
                }}
            />
            {options.map((option, index) => (
                <button
                    ref={(el) => (optionRefs.current[index] = el)}
                    key={option}
                    onClick={() => handleOptionClick(option)}
                    disabled={disabled}
                    style={{ minWidth: `${maxWidth}px` }}
                    className={`
                    ${optionsStyle}
                        relative z-10
                        flex items-center justify-center
                        px-[20px] py-[10px]
                        transition-all duration-200 text-center 
                        ${
                            selectedOption === option
                                ? 'text-primary-white text-context-bold'
                                : 'text-[#8B8B8B] text-context-regular'
                        }
                    `}
                >
                    {option}
                </button>
            ))}
        </div>
    );
};

export default Toggle;

// 사용법
{
    /* 
  <Toggle
    options={tabs}
    defaultSelected={tabs[0]}
    onChange={setTab}
    optionsStyle={'w-full'}
                    />
  */
}
