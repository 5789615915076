import Swal from 'sweetalert2';
import 'animate.css/animate.min.css';

export const showToast = (message: string, type: 'success' | 'error' | 'info' = 'info', backgroundColor?: string) => {
    Swal.fire({
        toast: true,
        position: 'top-end',
        icon: type,
        title: message,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        showClass: {
            popup: 'animate__animated animate__fadeInRight',
        },
        hideClass: {
            popup: 'animate__animated animate__fadeOutRight',
        },

        didOpen: (toast) => {
            // 커스텀 백그라운드 색상 적용
            let bgColor = type === 'success' ? '#EEFFF1' : type === 'info' ? '#DAEEFF' : '#FFE9E9';
            toast.style.backgroundColor = bgColor;
            toast.style.fontSize = '14px'; // 텍스트 사이즈를 14px로 설정
            toast.style.fontWeight = 'bold'; // 선택적으로 추가
            // 워드 브레이크 스타일 추가
            toast.style.wordBreak = 'keep-all';
            toast.style.overflowWrap = 'break-word';
            // let textColor = type === 'error' ? '#F03F3F' : 'inherit';
            // toast.style.color = textColor;
            // toast.style.color = getContrastYIQ(bgColor);
            toast.classList.add('custom-fade-in');
        },
        willClose: (toast) => {
            toast.classList.remove('custom-fade-in');
            toast.classList.add('custom-fade-out');
        },

        customClass: {
            popup: 'custom-toast-popup',
        },
    });
};

// 색상 대비를 위한 보조 함수 (글자 색상 자동 결정)
function getContrastYIQ(hexcolor: string) {
    const r = parseInt(hexcolor.slice(1, 3), 16);
    const g = parseInt(hexcolor.slice(3, 5), 16);
    const b = parseInt(hexcolor.slice(5, 7), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
}

// 사용 예시
// showToast('저장되었습니다!', 'success');
