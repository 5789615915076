// hooks
import { useState, useEffect } from 'react';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// icons
import { ReactComponent as MenuIcon } from 'assets/svgs/menu.svg';
// common
import Dropdown from 'components/dropdown/Default';
import MoreNavBtn from 'components/button/MoreNavBtn';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { ENDPOINT } from 'utils/consts/apiEndpoint';
import { dropDownFormChanger, dropDownFormChangerType } from 'utils/formChanger';

interface LineUpIdSubHeaderType {
    playerInfo: any;
    seasonStats: any;
    dropdownPlayerOpts: { value: string; label: string }[];
}
const LineUpIdSubHeader = ({ playerInfo, seasonStats, dropdownPlayerOpts }: LineUpIdSubHeaderType) => {
    const handleParamNavigate = useParamNavigate();
    const {
        totalAppearances = 0,
        totalGoals = 0,
        totalAssists = 0,
        totalAverageRating = 0.0,
    } = seasonStats?.totalStats || {};

    const [player, setPlayer] = useState(playerInfo?.id);
    const [teamName, setTeamName] = useState('TEAM');
    const [ageGroup, setAgeGroup] = useState('');
    const [ageGroupOpts, setAgeGroupsOpts] = useState<dropDownFormChangerType[]>([]);

    // react useApiQuery
    const { data, isLoading, isSuccess, isError, error, refetch } = useApiQuery(
        ['ageGroup'],
        ENDPOINT.staff.team.ageGroups,
        'get'
    );

    useEffect(() => {
        if (isSuccess && data) {
            const { teamName, ageGroups } = data;
            setTeamName(teamName);
            setAgeGroup(ageGroups[0]);
            const newAgeGroupOpts = dropDownFormChanger(ageGroups);
            setAgeGroupsOpts(newAgeGroupOpts);
        }
    }, [data]);
    useEffect(() => {
        if (ageGroup && player)
            handleParamNavigate({
                ageGroup: ageGroup,
                player: player,
            });
    }, [player]);
    return (
        <div className="h-[80px]">
            <div className="flex items-center justify-between gap-[220px]">
                <div className="flex gap-[14px] items-center w-[490]">
                    <MenuIcon className="text-[#BABABD] w-[30px] h-[30px] mr-[6px]" />
                    <div className="text-primary-white text-title-small-bold">
                        <div>갤로핑 FC 선수단</div>
                    </div>
                    <div className="gap-[7px] flex">
                        <Dropdown
                            options={ageGroupOpts}
                            value={ageGroup}
                            placeholder="연령"
                            onChange={setAgeGroup}
                            className={'w-[82px] border rounded-[8px] border-primary-white'}
                        />
                        <Dropdown
                            options={dropdownPlayerOpts}
                            value={player}
                            placeholder={
                                playerInfo?.uniformNumber
                                    ? `${playerInfo?.uniformNumber}. ${playerInfo?.name}`
                                    : '선수명'
                            }
                            onChange={setPlayer}
                            className={'border w-fit rounded-[8px] border-primary-white'}
                        />
                    </div>
                </div>
                <div className="flex  gap-[80px] ">
                    <div className="flex gap-[18px]  h-[48px] text-[#868686]">
                        <div className="w-[5px] bg-primary-green rounded-[5px]"></div>

                        <div className="flex gap-[15px] items-center">
                            <div className="flex items-center gap-[35px] ">
                                <div className="flex items-center gap-[5px]">
                                    <span className="text-[22px] ">{'출장'}</span>
                                    <span className="text-[50px] text-primary-green font-bold">{totalAppearances}</span>
                                </div>
                                <div className="flex items-center gap-[5px]">
                                    <span className="text-[22px]">{'평점'}</span>
                                    <span className="text-[50px] text-primary-white font-bold">
                                        {totalAverageRating.toFixed(2)}
                                    </span>
                                </div>
                                <div className="flex items-center gap-[5px]">
                                    <span className="text-[22px]">{'골'}</span>
                                    <span className="text-[50px] text-status-error font-bold">{totalGoals}</span>
                                </div>
                                <div className="flex items-center gap-[5px]">
                                    <span className="text-[22px]">{'도움'}</span>
                                    <span className="text-[50px] text-primary-white font-bold">{totalAssists}</span>
                                </div>
                                {/* <MoreNavBtn navigateUrl="/staff/control/lineUp/schedule" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LineUpIdSubHeader;
