import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axiosInstance from 'api/axiosInstance';
// redux
import { useDispatch } from 'react-redux';
import { setAuthState } from '../store/authSlice';
import { setUserInfoState } from 'store/userInfoSlice';

type ApiMethod = 'get' | 'post' | 'patch' | 'delete';

interface QueryOptions {
    enabled?: boolean;
    onSuccess?: (data: any) => void;
    onError?: (error: any) => void;
    [key: string]: any;
}

interface MutationOptions {
    onSuccess?: (data: any) => void;
    onError?: (error: any) => void;
    [key: string]: any;
}

export const useApiQuery = (
    key: string[],
    url: string,
    method: ApiMethod = 'get',
    options: QueryOptions = {},
    params?: Record<string, any>
) => {
    return useQuery({
        queryKey: key,
        queryFn: async () => {
            const response = await axiosInstance({
                url,
                method,
                params,
            });
            if (response?.status === 200) return response.data;
            else if (response?.status !== 200) {
                alert('200응답이 아님!');
                return response.data;
            }
            return response;
        },

        ...options,
    });
};

export const useApiMutation = (url: string, method: ApiMethod = 'post', options: MutationOptions = {}) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (data: any) => {
            const response = await axiosInstance({
                url,
                method,
                data,
            });
            return response.data;
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries();
            if (options.onSuccess) {
                options.onSuccess(data);
            }
        },
        onError: (error: any) => {
            if (options.onError) {
                const errorMessage = error.reason || error.message || '알 수 없는 오류가 발생했습니다.';
                console.log(`❌ ${errorMessage}`);
                options.onError(errorMessage);
            } else {
                console.error('🅰️ API Error:', error);
            }
        },
    });
};

export const useLoginMutation = (url: string, method: ApiMethod = 'post', options: MutationOptions = {}) => {
    const dispatch = useDispatch();

    return useMutation({
        mutationFn: async (data: any) => {
            const response = await axiosInstance({
                url,
                method,
                data,
            });
            return response; // 전체 응답 객체 반환
        },
        onSuccess: (response) => {
            const accessToken = response.headers['authorization']; // 헤더에서 토큰 추출
            const { refreshToken, name, id } = response.data;

            if (accessToken) {
                // localStorage.setItem('accessToken', accessToken.replace('Bearer ', '')); // 저장
                dispatch(
                    setAuthState({
                        accessToken,
                        refreshToken,
                    })
                );
                dispatch(
                    setUserInfoState({
                        name,
                        id,
                        email: null,
                        profileImageUrl: null,
                        teamNames: null,
                        role: null, // null 허용
                        staffRole: null, // 이미 null 허용됨
                        status: null,
                    })
                );
            } else {
                console.warn('🚨 토큰이 응답 헤더에 없습니다.');
            }

            if (options.onSuccess) {
                options.onSuccess(response.data);
            }
        },
        onError: (error: any) => {
            const errorMessage =
                error?.response?.data?.detailedMessage || error?.response?.data?.message || '로그인에 실패했습니다.';
            if (options.onError) {
                options.onError(errorMessage);
            } else {
                console.error('로그인 에러:', error);
            }
        },
    });
};

/*********************************** useApiQuery 사용법 ****************************************/
// const { data, isLoading, error, refetch } = useApiQuery(
//     ['userData'],
//     '/api/user'
// 'get',         // 메서드
// {
//   // 추가 옵션들
//   enabled: true,  // 쿼리 자동 실행 여부
//   staleTime: 5000, // 데이터 신선도 시간
//   cacheTime: 10000, // 캐시 유지 시간
//   onSuccess: (data) => {
//     // 성공 시 콜백
//     console.log(data);
//   },
//   onError: (error) => {
//     // 에러 시 콜백
//     console.error(error);
//   }
// }
// {
//     // 쿼리 파라미터들
//     page: 1,
//     limit: 10
//   }
//   );

//   // 버튼 클릭이나 특정 이벤트에서 refetch 호출
//   const handleRefresh = () => {
//     refetch();
//   };
