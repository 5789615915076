import { ReactComponent as MenuIcon } from 'assets/svgs/menu.svg';
// common
import StatusLabel from 'components/label/Default';

interface SubMainHeaderType {
    name?: string | null;
}
const SubMainHeader = ({ name = '홍길동' }: SubMainHeaderType) => {
    const playerSummarizedData = [
        { title: '최근 등수결과', score: 3, unit: '등', growth: 2, label: `3등` },
        {
            title: '또래 중 등수',
            score: 2,
            unit: '등',
            growth: -1,
            label: '1등',
        },
        {
            title: '역대 최고점수',
            score: 92.8,
            unit: '%',
            growth: 10,
            label: `+ 상위10%`,
        },
    ];
    return (
        <div className="h-[80px]">
            <div className="flex items-center justify-between">
                <div className="flex">
                    <MenuIcon className="text-[#BABABD] w-[30px] h-[30px] mt-[7px] mr-[6px]" />
                    <div className="text-primary-white text-title-small-bold">
                        <div>안녕하세요 {name}님,</div>
                        <div>축구실력을 높여볼까요?</div>
                    </div>
                </div>
                <div className="flex w-[970px]">
                    {playerSummarizedData.map((el) => (
                        <div
                            className="min-w-[311px] flex gap-[18px]  h-[48px] text-[#868686]"
                            key={el.title}
                        >
                            <div className="w-[5px] bg-primary-green rounded-[5px]"></div>
                            <div className="flex gap-[15px] items-center">
                                <div
                                    className="text-[20px] w-[72px] font-bold leading-[24px]"
                                    style={{ wordBreak: 'keep-all', overflowWrap: 'break-word' }} //한국어 띄어쓰기 단위로 줄바꿈
                                >
                                    {el.title}
                                </div>
                                <div className="flex items-center gap-[12px]">
                                    <div className="flex items-center gap-[5px]">
                                        <span className="text-[50px] text-primary-white font-bold">{el.score}</span>
                                        <span className="text-[22px]">{el.unit}</span>
                                    </div>
                                    <div>
                                        <StatusLabel
                                            status={
                                                el.unit === '등' && el.growth > 0
                                                    ? 'up'
                                                    : el.unit === '등' && el.growth <= 0
                                                    ? 'down'
                                                    : null
                                            }
                                            customColor="bg-primary-green text-primary-black font-bold text-[16px]"
                                        >
                                            {el.label}
                                        </StatusLabel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SubMainHeader;
