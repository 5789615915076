import { ReactComponent as MenuIcon } from 'assets/svgs/menu.svg';

const MypageSubHeader = () => {
    return (
        <div className="h-[80px]">
            <div className="flex items-center justify-between">
                <div className="flex gap-[14px] items-center">
                    <MenuIcon className="text-[#BABABD] w-[30px] h-[30px]  mr-[6px]" />
                    <div className="text-primary-white text-title-small-bold">김선수(갤로핑FC)님,마이페이지</div>
                </div>
                <div className="flex w-[962px] gap-[80px]">
                    <div className=" flex gap-[18px]  h-[48px] text-[#868686]">
                        <div className="w-[5px] bg-primary-green rounded-[5px]"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MypageSubHeader;
