import { useState } from 'react';
import { ReactComponent as TwinkleIcon } from 'assets/svgs/twinkle.svg';

const RatingCard = ({ isEdit }: { isEdit: boolean }) => {
    // textarea 상태 관리
    const [ratingData, setRatingData] = useState([
        {
            title: 'AI 인공 지능 분석',
            content:
                '피지컬이 좋은데, 예의가 바르시고 착한것으로 보여집니다. 이 선수의 전술력만 보완이 된다면 아주 훌륭한 선수의 잠재력을 가지고 있습니다. 그로 인해 전술에 대한 부분을 훈련시키고 보완시킬 것으로 보여집니다.',
        },
        { title: '장점', content: '가나다라' },
        { title: '인성', content: '' },
        { title: '총평', content: '' },
    ]);

    // 값 변경 처리
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
        const newValue = event.target.value;
        setRatingData((prev) => prev.map((item, i) => (i === index ? { ...item, content: newValue } : item)));
    };

    return (
        <div className="gap-[20px] flex flex-col">
            {ratingData.map((label, index) => (
                <div
                    className="flex items-center justify-between"
                    key={index}
                >
                    <div className="w-[103px] flex font-bold">
                        <div className="w-[28px]">
                            {index === 0 && (
                                <span className="w-[24px] h-[24px]">
                                    <TwinkleIcon />
                                </span>
                            )}
                        </div>
                        <div
                            className={index === 0 ? 'text-primary-purple w-[75px]' : ''}
                            style={{ wordBreak: 'keep-all', overflowWrap: 'break-word' }} // 한국어 띄어쓰기 단위로 줄바꿈
                        >
                            {label.title}
                        </div>
                    </div>
                    <textarea
                        className={`w-[1217px] text-secondary-dGrayDark resize-none bg-secondary-mGrayLight rounded-[8px] p-[12px] text-context-regular ${
                            index > 0 && isEdit
                                ? 'focus:outline-primary-purple focus:bg-primary-white'
                                : 'bg-secondary-mGrayLight focus:outline-none'
                        } 
                        ${!label.content || label?.content?.length < 30 ? 'h-[44px]' : 'h-auto'}
                        `}
                        maxLength={300}
                        readOnly={!isEdit || index === 0} // 읽기 전용 설정
                        value={label.content}
                        onChange={(e) => handleChange(e, index)} // 값 변경 처리
                        style={{
                            overflow: 'hidden',
                        }}
                    />
                </div>
            ))}
        </div>
    );
};

export default RatingCard;
