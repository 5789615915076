import React, { useState, useEffect } from "react";
// commons
import EmptyBar from "components/emptyBar";
import CustomTable from "components/table/CustomTable";
import Dropdown from "components/dropdown/Default";
// utils
import { useParamNavigate } from "hooks/useDynamicNavigate";
import { ratingData } from "utils/tmp/staffTmpData";
// staff components
import ControlRatingSubHeader from "pages/staff/control/rating/RatingSubHeader";
// import LineUpList from 'pages/staff/components/LineUpList';
const Rating = () => {
  const handleParamNavigate = useParamNavigate();
  const columns = [
    { key: "grade", label: "학년", sortable: true },
    { key: "backNumber", label: "배번", sortable: true },
    { key: "position", label: "포지션" },
    { key: "name", label: "이름", sortable: false },
    { key: "rank", label: "등급", sortable: true },
    { key: "leagueParticipant", label: "리그 출전", sortable: false },
    { key: "history", label: "직전 소속", sortable: true },
    { key: "link", label: "상세보기", sortable: true },
    {
      key: "rating",
      label: "평가",
    },
  ];
  const options = [
    { value: "2024_1", label: "2024년 1차" },
    { value: "2024_2", label: "2024년 2차" },
    { value: "2024_3", label: "2024년 3차" },
  ];
  const [branch, setBranch] = useState(options[options.length - 1 || 0]?.value);
  useEffect(() => {
    if (branch)
      handleParamNavigate({
        branch: branch,
      });
  }, [branch]);
  return (
    <div className="w-[1400px] m-auto">
      <ControlRatingSubHeader />
      <EmptyBar customStyle={"h-[35px]"} />

      <div className="w-full  bg-white rounded-[10px] p-[30px]">
        <div className="flex gap-[20px] items-center">
          <Dropdown
            options={options}
            value={branch}
            onChange={setBranch}
            className="w-[140px] "
          />
          <div className="flex gap-[5px] text-[20px] font-semibold">
            <span className="text-[#868686]">입력기간</span>
            <div className="flex gap-[10px]">
              <span>2024.01.01</span>
              <span className="text-[#868686]">~</span>
              <span>2024.01.15</span>
            </div>
          </div>
        </div>
        <EmptyBar customStyle={"h-[20px]"} />

        <div className="w-full">
          <CustomTable
            columns={columns}
            rows={ratingData}
            equalWidth={false}
            minWidth={"min-w-[116px]"}
          />
        </div>
      </div>

      <EmptyBar customStyle={"h-[20px]"} />
    </div>
  );
};

export default Rating;
