// hooks
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// api
import { useApiQuery } from "hooks/useDynamicApi";
// icons
import { ReactComponent as MenuIcon } from "assets/svgs/menu.svg";
// common
import StatusLabel from "components/label/Default";
import Dropdown from "components/dropdown/Default";
// utils
import { seasonYearOptions } from "utils/consts/controlInfo";
import { useParamNavigate } from "hooks/useDynamicNavigate";
import { ENDPOINT } from "utils/consts/apiEndpoint";
import {
  dropDownFormChanger,
  dropDownFormChangerType,
} from "utils/formChanger";

interface ControlSubHeaderType {
  wins: number;
  draws: number;
  losses: number;
  winRate: number;
  winRateChange: number;
}
const ControlSubHeader = ({
  seasonStats,
}: {
  seasonStats: ControlSubHeaderType | null;
}) => {
  const handleParamNavigate = useParamNavigate();
  const {
    wins = 0,
    draws = 0,
    losses = 0,
    winRate = 0.0,
    winRateChange = 0.0,
  } = seasonStats || {};

  const today = new Date();
  const toDayYear = String(today.getFullYear()); // 현재 연도 출력
  const [teamName, setTeamName] = useState("TEAM");
  const [seasonYear, setSeasonYear] = useState(toDayYear);
  const [ageGroup, setAgeGroup] = useState("");
  const [ageGroupOpts, setAgeGroupsOpts] = useState<dropDownFormChangerType[]>(
    []
  );
  useEffect(() => {
    if (ageGroup)
      handleParamNavigate({
        year: seasonYear,
        ageGroup: ageGroup,
      });
  }, [ageGroup, seasonYear]);
  // react useApiQuery
  const { data, isLoading, isSuccess, isError, error, refetch } = useApiQuery(
    ["ageGroup"],
    ENDPOINT.staff.team.ageGroups,
    "get"
  );
  useEffect(() => {
    if (isSuccess && data) {
      const { teamName, ageGroups } = data;
      setTeamName(teamName);
      setAgeGroup(ageGroups[0]);
      const newAgeGroupOpts = dropDownFormChanger(ageGroups);
      setAgeGroupsOpts(newAgeGroupOpts);
    }
  }, [data]);

  return (
    <div className="h-[80px]">
      <div className="flex items-center justify-between">
        <div className="flex gap-[14px] items-center">
          <MenuIcon className="text-[#BABABD] w-[30px] h-[30px]  mr-[6px]" />
          <div className="text-primary-white text-title-small-bold">
            <div>{teamName}</div>
          </div>
          <Dropdown
            defaultValue={toDayYear}
            options={seasonYearOptions}
            value={seasonYear}
            onChange={setSeasonYear}
            className={"w-[95px] border rounded-[8px] border-primary-white"}
          />
          <Dropdown
            options={ageGroupOpts}
            value={ageGroup}
            placeholder="연령"
            onChange={setAgeGroup}
            className={"w-fit border rounded-[8px] border-primary-white"}
          />
        </div>
        <div className="flex  gap-[80px]">
          {/* w-[760px] */}
          <div className="min-w-[311px] flex gap-[18px]  h-[48px] text-[#868686]">
            <div className="w-[5px] bg-primary-green rounded-[5px]"></div>
            <div className="flex gap-[15px] items-center">
              <div
                className="w-[88px] font-semibold leading-[24px] text-[20px]"
                style={{ wordBreak: "keep-all", overflowWrap: "break-word" }} //한국어 띄어쓰기 단위로 줄바꿈
              >
                {"2024시즌 승률"}
              </div>
              <div className="flex items-center gap-[12px]">
                <div className="flex items-center gap-[5px]">
                  <span className="text-[50px] text-primary-white font-bold">
                    {winRate.toFixed(1)}
                  </span>
                  <span className="text-[22px]">{"%"}</span>
                </div>
                <div>
                  <StatusLabel
                    status={"up"}
                    customColor="bg-primary-green text-primary-black font-bold  text-[16px]"
                  >
                    {winRateChange.toFixed(1)}
                  </StatusLabel>
                </div>
              </div>
            </div>
          </div>
          <div className="min-w-[311px] flex gap-[18px]  h-[48px] text-[#868686]">
            <div className="w-[5px] bg-primary-green rounded-[5px]"></div>
            <div className="flex gap-[15px] items-center">
              <div
                className="w-[72px] font-bold leading-[24px] text-[20px]"
                style={{ wordBreak: "keep-all", overflowWrap: "break-word" }} //한국어 띄어쓰기 단위로 줄바꿈
              >
                {"총 시즌 정보"}
              </div>
              <div className="flex items-center gap-[25px]">
                <div className="flex items-center gap-[5px]">
                  <span className="text-[50px] text-primary-green font-bold">
                    {wins}
                  </span>
                  <span className="text-[22px]">{"승"}</span>
                </div>
                <div className="flex items-center gap-[5px]">
                  <span className="text-[50px] text-primary-white font-bold">
                    {draws}
                  </span>
                  <span className="text-[22px]">{"무"}</span>
                </div>
                <div className="flex items-center gap-[5px]">
                  <span className="text-[50px] text-status-error font-bold">
                    {losses}
                  </span>
                  <span className="text-[22px]">{"패"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlSubHeader;
